import ApiService from "../api/apiService";
import getCookie from "./getToken";

const verifyToken = async () => {
  try {
    const tokenCookie = getCookie("token");

    if (!tokenCookie) return false;

    const response = await ApiService.token.verifyToken(tokenCookie);

    const { success, valid } = response;

    if (!success || !valid) {
      return false;
    }

    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export default verifyToken;
