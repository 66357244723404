import React from "react";
import "../../styles/loader.css";

function Loader({ isLoading }) {
  return (
    <>
      {isLoading && (
        <div className='loader-whole-page-container'>
          <div className='loader-whole-page'></div>
        </div>
      )}
    </>
  );
}

export default Loader;
