function ChatInputReply(props) {
  const { toggleShowChatReply, chatReplyData, userDetails, setChatReplyData } = props;
  const { chat_id, conversation_id, display_name, message } = chatReplyData;
  const handleCloseReply = () => {
    setChatReplyData(null);
  };

  const isReplyingToOwn = userDetails.display_name === display_name;
  return (
    <div className='message__input-reply-container'>
      <span className='message__input-reply-sender'>
        <b>
          Replying to <span className=''>{isReplyingToOwn ? "yourself" : display_name}</span>
        </b>
      </span>
      <span className='message__input-reply-message'>{message}</span>
      <button className='message__input-reply-close' onClick={handleCloseReply}>
        X
      </button>
    </div>
  );
}

export default ChatInputReply;
