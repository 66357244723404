import React, { useState, useEffect, useContext } from "react";
import Table from "./reusable/table";
import ApiService from "../../api/apiService";
import showSwal from "../swal";
import { common } from "../../util/common";
import { convertTo12HourPlusDateFormat, formatToPeso } from "../../util/helperFunctions";
import RequestItemList from "./reusable/request-item-list";
import FormsSearch from "./reusable/forms-search";

function RequestMain({ handleShowingModal }) {
  const [myRequestsData, setRequestData] = useState([]);
  const [originalRequestsData, setOriginalRequestData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const getMyRequests = async () => {
      const myRequests = await ApiService.forms.getMyRequests();
      const { success, requests } = myRequests;

      if (!success) return showSwal(common.errors.GETTING_MY_REQUESTS_ERROR);

      const _requestData = requests.map((request) => ({
        id: request.id,
        request_id: request.reference_id,
        request_type: request.request_type,
        link_id: request.request_link_id,
        total_cost: formatToPeso(request.total_cost),
        status: request.overall_approval_status === true ? "Approved" : "Pending",
        date: convertTo12HourPlusDateFormat(request.createdAt),
      }));
      setOriginalRequestData(_requestData);
      setRequestData(_requestData);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };

    getMyRequests();
  }, []);

  const handleSearch = (query) => {
    if (query.trim() === "") {
      setRequestData(originalRequestsData);
    } else {
      const filteredData = originalRequestsData.filter(
        (requestData) =>
          requestData?.request_id?.toLowerCase().includes(query.toLowerCase()) ||
          requestData?.request_type?.toLowerCase().includes(query.toLowerCase()) ||
          requestData?.date?.toLowerCase().includes(query.toLowerCase())
      );
      setRequestData(filteredData);
    }
  };

  return (
    <div>
      <div className='request-main-title'>
        <h3>My Requests</h3>
        <div className='title-new-request'>
          <a href='#' onClick={(e) => handleShowingModal()}>
            + Add New Request
          </a>
        </div>
        <FormsSearch handleSearch={handleSearch} />
      </div>
      {isLoading ? (
        <div className='loader--container'>
          <div className='loader--'></div>
        </div>
      ) : (
        <div className='request-main---container'>
          {myRequestsData.map((requestData, index) => (
            <RequestItemList requestData={requestData} key={index} />
          ))}
        </div>
      )}
    </div>
  );
}

export default RequestMain;
