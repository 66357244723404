import React, { useState, useEffect, useRef, useContext } from "react";
import "../styles/style.css";
import ApiService from "../api/apiService";
import ChatSideBarItem from "./chat-sidebar-item";
import MessageHead from "./message-head";
import MessageSeparator from "./message-separator";
import MessageSender from "./message-sender";
import MessageReceiver from "./message-receiver";
import showSwal from "./swal";
import ConversationModal from "./create-conversation-modal";
import PreviewImageModal from "./preview-image-modal";
import SearchChatModal from "./search-chat-modal";
import ViewMembersModal from "./view-members-modal";
import ViewConversationFilesModal from "./view-conversation-files-modal";
import ViewActivityLogsModal from "./view-activity-logs";
import ChatInputReply from "./chat-input-reply";
import emptyImage from "../images/empty.jpg";
import buildHubLogo from "../images/bg.png";
import "font-awesome/css/font-awesome.min.css";
import showNotification from "../hooks/user-notification";
import { common } from "../util/common";
import { WebSocketContext, ChatContext } from "../context/contexts";
import loader2 from "../images/loader_2.gif";
import loader3 from "../images/loader_3.gif";
import autosize from "autosize";
import ChangePasswordModal from "./change-password-modal";
import { formatDate } from "../util/helperFunctions";
import EditConversationModal from "./edit-conversation-modal";
import ViewThreadModal from "./view-thread-modal";
import notifSound from "../sounds/notif-sound.mp3";
import SearchInput from "./reusable/search-input";
import SearchResults from "./search-results";
import InfiniteScroll from "react-infinite-scroll-component";
import ImageViewer from "./reusable/image-viewer";
import { useSearchParams } from "react-router-dom";
import MessageTyping from "./message-typing";
import ReactionOptions from "./reusable/reaction-options";
import ToggleSwitch from "./reusable/toggle-switch";
import PinnedMessage from "./pin-message";
import PinModal from "./pin-message-modal";
import SidebarHeader from "./sidebar-header";
import BuildhubTextLogo from "../images/buildhub.ph.png";

function Main(props) {
  // WS
  const WS = useContext(WebSocketContext);
  // WS
  const Chat = useContext(ChatContext);

  const fileInputRef = useRef(null);
  // AUTOSIZE
  const msgInputTextArea = document.querySelector(".message__input");
  autosize(msgInputTextArea);

  const { userDetails, linkConversation, showHideChangePasswordModal, viewChangePasswordModal } = props;
  let userId;
  if (userDetails && userDetails.id) {
    userId = userDetails.id;
  }

  const [conversationMemberSuggestions, setConversationMemberSuggestions] = useState([]);

  const [error, setError] = useState(null);
  const [messageContent, setMessageContent] = useState("");

  const [conversationModalShow, setConversationModalShow] = useState(false);
  const [editConversationModalShow, setEditConversationModalShow] = useState(false);
  const [searchModalShow, setSearchModalShow] = useState(false);

  const [viewMembersModal, setViewMembersModalShow] = useState(false);
  const [viewConversationFilesModal, setViewConversationFilesModalShow] = useState(false);
  const [viewActivityLogsShow, setViewActivityLogsShow] = useState(false);

  const [selectedFile, setSelectedFile] = useState([]);
  const [filePreview, setFilePreview] = useState([]);

  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);

  const [oldScrollHeight, setOldScrollHeight] = useState(0);
  const oldScrollHeightRef = useRef(oldScrollHeight);

  const [isAtBottom, setIsAtBottom] = useState(true);

  const [isShowChatInputReply, setIsShowChatInputReply] = useState(false);

  const [mentionText, setMentionText] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);

  const [selectedMentionData, setSelectedMentionData] = useState([]);

  const [isShowingNotification, setIsShowingNotification] = useState(false);

  const [isConnectedRoom, setConnectedRoom] = useState(false);

  const [isSendingMsg, setIsSendingMsg] = useState(false);

  const [searchQuery, setSearchQuery] = useState("");
  const [isShowingSearchResults, setIsShowingSearchResults] = useState(false);
  const [globalSearchResults, setGlobalSearchResults] = useState([]);

  const [openLightbox, setOpenLightbox] = useState(false);
  const [lightboxSrcs, setLightboxSrcs] = useState([]);

  const [searchParams, setSearchParams] = useSearchParams();

  const [isAbleToLoad, setAbleToLoad] = useState(true);

  const [showReactionOptionList, setShowReactionOptionList] = useState(false);

  useEffect(() => {
    let timeoutId;

    if (Chat.isLoading) {
      setAbleToLoad(false);
    } else {
      timeoutId = setTimeout(() => {
        setAbleToLoad(true);
      }, 500);
    }

    return () => clearTimeout(timeoutId);
  }, [Chat.isLoading]);

  const [divHeight, setDivHeight] = useState("83vh");

  useEffect(() => {
    const calculateHeight = () => {
      const windowHeight = window.innerHeight;
      const calculatedHeight = windowHeight * 0.65; // 60% of the screen height
      setDivHeight(`${calculatedHeight}px`);
    };

    // Calculate height on initial load
    calculateHeight();

    // Recalculate height on window resize
    window.addEventListener("resize", calculateHeight);

    // Cleanup event listener on unmount
    return () => window.removeEventListener("resize", calculateHeight);
  }, []);

  useEffect(() => {
    if (!linkConversation) return;
    const query_size = searchParams.get("_q");
    const message_id = searchParams.get("q");
    const thread_mesage_id = searchParams.get("_t");
    const thread_reply = searchParams.get("_tr");

    if (query_size && message_id) {
      Chat.reSetConversationDetail(linkConversation, query_size, function callback() {
        setTimeout(() => {
          let _message_id = message_id;

          if (thread_mesage_id && thread_reply === "true") {
            _message_id = thread_mesage_id;
            Chat.handleThreadViewMsgId(thread_mesage_id, linkConversation);
          }
          const msgView = document.getElementById(_message_id);
          if (msgView) {
            const coloredElement = msgView.querySelector("span");

            coloredElement.classList.add("pulse");

            msgView.scrollIntoView({ behavior: "smooth" });

            setTimeout(() => {
              coloredElement.classList.remove("pulse");
            }, 2500);
          }
        }, 1500);
      });
    } else {
      Chat.setConversationDetail(linkConversation);
    }
  }, [linkConversation]);

  const showSearchResults = () => {
    setIsShowingSearchResults(true);
  };
  const hideSearchResults = () => {
    setIsShowingSearchResults(false);
  };

  const handleMessageInput = (e) => {
    const input = e.target.value;
    setMessageContent(input);

    const mentionMatch = input.match(/@@(\w*)$/);
    if (mentionMatch) {
      setMentionText(mentionMatch[1]);
      setShowSuggestions(true);
    } else {
      setShowSuggestions(false);
    }

    updateMentions(input);
  };

  const handleCurrentlyTyping = (e) => {
    WS.websocketInstance.currentlyTyping(
      userDetails.id,
      userDetails.display_name,
      userDetails.profile_photo,
      Chat.storedConversationId
    );
  };

  const updateMentions = (input) => {
    const mentionRegex = /@@([\w\s]+?)@@/g;
    let match;
    const mentions = [];

    while ((match = mentionRegex.exec(input)) !== null) {
      mentions.push(match[1]);
    }

    const updatedMentionData = selectedMentionData.filter((mention) => mentions.includes(mention.name));
    setSelectedMentionData(updatedMentionData);
  };

  const handleSuggestionClick = (suggestionObj) => {
    const updatedMessage = messageContent.replace(/@\w*$/, `@${suggestionObj.name}@@ `);
    setMessageContent(updatedMessage);
    setSelectedMentionData((prevState) => [...prevState, suggestionObj]);
    setShowSuggestions(false);
  };

  const toggleShowChatReply = () => {
    setIsShowChatInputReply((prevState) => !prevState);
  };

  const checkScrollPosition = () => {
    const messageMain = Chat.messageMainRef.current;
    const isScrolledToBottom = messageMain.scrollHeight - messageMain.clientHeight <= messageMain.scrollTop + 1;
    setIsAtBottom(isScrolledToBottom);
  };

  const scrollToBottom = () => {
    const messageMain = Chat.messageMainRef.current;
    if (messageMain) {
      messageMain.scrollTo({
        top: messageMain.scrollHeight - messageMain.clientHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (mentionText) {
      const filteredSuggestions = Chat.conversationMembersData.filter((member) =>
        member.user.display_name.toLowerCase().startsWith(mentionText.toLowerCase())
      );
      setConversationMemberSuggestions(filteredSuggestions);
    }
  }, [mentionText]);

  useEffect(() => {
    const messageMain = Chat.messageMainRef.current;
    if (messageMain) {
      messageMain.addEventListener("scroll", checkScrollPosition);
      return () => {
        messageMain.removeEventListener("scroll", checkScrollPosition);
      };
    }
  }, []);

  const closeModal = () => {
    setIsPreviewOpen(false);
    setTimeout(() => setPreviewImage(null), 300);
  };

  const handleImageClick = (imageSrc) => {
    const imgSrc = [];
    for (const image of imageSrc) {
      imgSrc.push(image);
    }
    setLightboxSrcs(imgSrc);
    setOpenLightbox(true);
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    const maxFileSizeMB = process.env.REACT_APP_MAX_ATTACHMENT_SIZE_IN_MB;

    const newFilePreviews = [];
    const newSelectedFiles = [];

    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const fileSizeMB = files[i].size / 1024 / 1024;
        if (fileSizeMB > maxFileSizeMB) {
          showSwal({
            title: "Something went wrong",
            icon: "error",
            text: `File ${files[i].name} exceeds 15MB and will not be uploaded.`,
            confirmButtonText: "OK",
          });
          continue;
        }
        const objProperty = {
          filePreview: URL.createObjectURL(files[i]),
          file: files[i],
          type: files[i].type,
        };
        newSelectedFiles.push(objProperty);
        newFilePreviews.push(objProperty);
      }
      setSelectedFile(newSelectedFiles);
      setFilePreview(newFilePreviews);
    }
  };

  const handleSendMessage = async () => {
    if (isSendingMsg) return;
    if (!Chat.storedConversationId) return showSwal(common.errors.INVALID_CONVERSATION);

    let _messageContent = messageContent;
    if (!messageContent) _messageContent = " ";

    try {
      const formData = new FormData();

      if (selectedFile !== "") {
        selectedFile.forEach((fileObj) => {
          formData.append("message_attachments", fileObj.file);
        });
      }
      formData.append(
        "data",
        JSON.stringify({
          message: _messageContent,
          reply_to_message_id: Chat.chatReplyData ? Chat.chatReplyData.chat_id : null,
          mention_data: selectedMentionData,
        })
      );
      setIsSendingMsg(true);
      document.querySelector(".message__btn-send-icon").style.display = "none";
      document.querySelector(".loader-msg").style.display = "block";
      const messageDetail = await ApiService.message.sendMessage(formData, Chat.storedConversationId);
      const { success, message } = messageDetail;

      if (success) {
        WS.websocketInstance.sendMessage({ message: messageDetail.message, type: "user-chat" });
        document.querySelector(".message__btn-send-icon").style.display = "inline-block";
        document.querySelector(".loader-msg").style.display = "none";
        setIsSendingMsg(false);
        setMessageContent("");
        setFilePreview([]);
        setSelectedFile([]);
        fileInputRef.current.value = "";
        setSelectedMentionData([]);
        Chat.setChatReplyData(null);
        // setTimeout(() => {
        //   Chat.scrollToBottom();
        // }, 350);
      } else {
        showSwal(common.errors.SEND_MESSAGE_ERROR);
      }
    } catch (error) {
      showSwal(common.errors.SEND_MESSAGE_ERROR);
      setError(error);
    }
  };

  const showHideConversationModal = () => {
    setConversationModalShow(!conversationModalShow);
  };
  const showHideEditConversationModal = () => {
    setEditConversationModalShow(!editConversationModalShow);
  };

  const showHideSearchmodal = () => {
    setSearchModalShow(!searchModalShow);
  };

  const showHideViewMembersModal = () => {
    setViewMembersModalShow(!viewMembersModal);
  };

  const showHideViewConversationFilesModal = () => {
    setViewConversationFilesModalShow(!viewConversationFilesModal);
  };

  const showHideViewActivityLogsModal = () => {
    setViewActivityLogsShow(!viewActivityLogsShow);
  };

  useEffect(() => {
    Chat.queryPageSizeRef.current = Chat.queryPageSize;
  }, [Chat.queryPageSize]);

  useEffect(() => {
    oldScrollHeightRef.current = oldScrollHeight;
  }, [oldScrollHeight]);

  useEffect(() => {
    let intervalId;

    const fetchMessages = async () => {
      if (!Chat.storedConversationId) return;
      try {
        const messageMainDiv = Chat.messageMainRef.current;
        const oldScrollHeight = messageMainDiv.scrollHeight;
        const scrollTop = messageMainDiv.scrollTop;

        const messagesDetail = await ApiService.message.getConversationMessages(
          Chat.storedConversationId,
          1,
          Chat.queryPageSizeRef.current
        );
        Chat.setMessagesData(messagesDetail);

        requestAnimationFrame(() => {
          const newScrollHeight = messageMainDiv.scrollHeight;
          // messageMainDiv.scrollTop = newScrollHeight - oldScrollHeight + scrollTop;
        });
      } catch (error) {
        setError(error);
      }
    };

    if (Chat.storedConversationId) {
      // intervalId = setInterval(fetchMessages, 1000);
      fetchMessages();
    }

    return () => clearInterval(intervalId);
  }, [Chat.storedConversationId]);

  const handleViewMore = async (e) => {
    const messageMainDiv = Chat.messageMainRef.current;
    const { scrollHeight } = messageMainDiv;

    setOldScrollHeight(scrollHeight);
    Chat.setQueryPageSize((prevPageSize) => prevPageSize + 10);
    await Chat.updateConversationData("", true, scrollHeight, Chat.queryPageSize + 10);
  };

  useEffect(() => {
    const handleResize = () => {
      const maxWidth = 120 * parseFloat(getComputedStyle(document.documentElement).fontSize);
      Chat.setMobileMode(window.innerWidth <= maxWidth);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const conversationProp = props.conversations ? props.conversations : [];
  const messages = Chat.messagesData ? Chat.messagesData.messages : [];
  // const conversationActions = Chat.messagesData ? Chat.messagesData.conversation_actions : [];

  const reversedMessages = [...messages].reverse();
  // const sortedActions = [...conversationActions].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  const allItems = [
    ...reversedMessages,
    // ...sortedActions
  ].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  const pinnedConversations = conversationProp.filter((conversation) => conversation.is_pinned);
  const unpinnedConversations = conversationProp.filter((conversation) => !conversation.is_pinned);
  pinnedConversations.sort((a, b) => {
    const dateA = new Date(a.last_message.updatedAt);
    const dateB = new Date(b.last_message.updatedAt);

    return dateB - dateA;
  });

  unpinnedConversations.sort((a, b) => {
    const dateA = new Date(a.last_message.updatedAt);
    const dateB = new Date(b.last_message.updatedAt);

    return dateB - dateA;
  });
  let totalUnreadCount = 0;
  if (conversationProp.length > 0) {
    conversationProp.map((chatDetail) => {
      totalUnreadCount += parseInt(chatDetail.unread_count);
    });
  }
  useEffect(() => {
    let intervalId;

    if (totalUnreadCount > 0) {
      if (!Chat.isMobileMode) {
        showNotification();
      }
      const __notif = new Audio(notifSound);
      __notif.play().catch((err) => {});

      setIsShowingNotification(true);
      document.title = `(${totalUnreadCount}) New Messages - Buildhub Internal Chat`;

      intervalId = setInterval(() => {
        setIsShowingNotification((prevState) => !prevState);
        document.title = isShowingNotification
          ? `(${totalUnreadCount}) New Messages - Buildhub Internal Chat`
          : "Buildhub Internal Chat";
      }, 2000);
    } else {
      setIsShowingNotification(false);
      document.title = "Buildhub Internal Chat";
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [totalUnreadCount]);

  const [____isDeleted, ____setIsDeleted] = useState(false);
  useEffect(() => {
    const _newMessagesData = Chat.messagesData;
    const _newWsMessagesData = Chat.wsMessagesData;

    if (_newMessagesData) {
      let matchingChat;

      matchingChat = _newMessagesData.messages.find((message) => message.id === Chat.wsDeleteActionsMessageId);
      if (!matchingChat) {
        if (_newWsMessagesData) {
          matchingChat = _newWsMessagesData.find((message) => message.id === Chat.wsDeleteActionsMessageId);
        }
      }

      if (matchingChat) {
        matchingChat.is_deleted = true;

        Chat.messagesData = {
          ..._newMessagesData,
          messages: [..._newMessagesData.messages],
        };
      } else {
      }
    }
  }, [Chat.wsDeleteActionsMessageId]);

  useEffect(() => {
    const _newMessagesData = Chat.messagesData;
    const _newWsMessagesData = Chat.wsMessagesData;

    if (_newMessagesData) {
      let matchingChat;

      matchingChat = _newMessagesData.messages.find(
        (message) => message.id === Chat.wsTheradMsgs?.reply_to_thread_message_id
      );
      if (!matchingChat) {
        if (_newWsMessagesData) {
          matchingChat = _newWsMessagesData.find(
            (message) => message.id === Chat.wsTheradMsgs?.reply_to_thread_message_id
          );
        }
      }

      if (matchingChat) {
        matchingChat.thread_replies.push(Chat.wsTheradMsgs);

        Chat.messagesData = {
          ..._newMessagesData,
          messages: [..._newMessagesData.messages],
        };
      } else {
      }
    }
  }, [Chat.wsTheradMsgs]);

  useEffect(() => {
    const _newMessagesData = Chat.messagesData;
    const _newWsMessagesData = Chat.wsMessagesData;

    if (_newMessagesData) {
      let matchingChat;

      matchingChat = _newMessagesData.messages.find(
        (message) => message.id === Chat.wsReactionMessageDetail?.message_id
      );
      if (!matchingChat) {
        if (_newWsMessagesData) {
          matchingChat = _newWsMessagesData.find((message) => message.id === Chat.wsReactionMessageDetail?.message_id);
        }
      }
      if (matchingChat) {
        matchingChat.message_reactions.push(Chat.wsReactionMessageDetail);

        Chat.messagesData = {
          ..._newMessagesData,
          messages: [..._newMessagesData.messages],
        };
      }
    }
  }, [Chat.wsReactionMessageId]);
  useEffect(() => {
    const _newMessagesData = Chat.messagesData;
    const _newWsMessagesData = Chat.wsMessagesData;

    if (_newMessagesData) {
      let matchingChat;

      matchingChat = _newMessagesData.messages.find(
        (message) => message.id === Chat.wsReactionMessageDetail?.message_id
      );
      if (!matchingChat) {
        if (_newWsMessagesData) {
          matchingChat = _newWsMessagesData.find((message) => message.id === Chat.wsReactionMessageDetail?.message_id);
        }
      }
      const isReactionRemoved = Chat?.wsReactionMessageDetail?.type === "removed";
      if (matchingChat && isReactionRemoved) {
        matchingChat.message_reactions = matchingChat.message_reactions.filter(
          (reaction) => reaction.id !== Chat.wsReactionMessageDetail.id
        );

        Chat.messagesData = {
          ..._newMessagesData,
          messages: [..._newMessagesData.messages],
        };
      }
    }
  }, [Chat.wsDeletedReactionMessageId]);

  useEffect(() => {
    const messageData = Chat?.messagesData;
    const wsMessageData = Chat?.wsMessagesData;
    const seenMessageData = Chat?.seenMessageData;

    if (messageData?.messages && seenMessageData && seenMessageData.user_id) {
      let matchingChat = null;

      messageData.messages.forEach((message) => {
        if (message?.message_reads) {
          message.message_reads = message.message_reads.filter((read) => read?.user_id !== seenMessageData.user_id);
        }
      });

      if (wsMessageData?.length) {
        wsMessageData.forEach((wsMsgData) => {
          if (wsMsgData?.message_reads) {
            wsMsgData.message_reads = wsMsgData.message_reads.filter(
              (read) => read?.user_id !== seenMessageData.user_id
            );
          }
        });
      }

      matchingChat = messageData.messages.find((message) => message?.id === seenMessageData.message_id);

      if (!matchingChat && wsMessageData?.length) {
        matchingChat = wsMessageData.find((message) => message?.id === seenMessageData.message_id);
      }

      if (matchingChat?.message_reads) {
        const { user_id, display_name } = seenMessageData;

        matchingChat.message_reads.push({
          id: "-WS",
          user: {
            id: user_id,
            display_name: display_name || "Unknown",
          },
          user_id,
        });

        Chat.messagesData = {
          ...messageData,
          messages: [...messageData.messages],
        };
      }
    }
  }, [Chat?.seenMessageData]);

  useEffect(() => {
    if (Chat && Chat.conversationData && Chat.conversationData.conversation) {
      Chat.conversationData.conversation.pinned_message = Chat.pinMessageData ? Chat.pinMessageData.pin_data : null;
    } else {
      // Initialize the conversationData and conversation objects if they don't exist
      Chat.conversationData = Chat.conversationData || {};
      Chat.conversationData.conversation = Chat.conversationData.conversation || {};
      Chat.conversationData.conversation.pinned_message = Chat.pinMessageData ? Chat.pinMessageData.pin_data : null;
    }

    const WSPinData = Chat?.pinMessageData;

    const _newMessagesData = Chat.messagesData;
    const _newWsMessagesData = Chat.wsMessagesData;

    if (_newMessagesData) {
      let matchingChat;

      matchingChat = _newMessagesData.messages.find((message) => message.id === WSPinData?.pin_data?.id);
      if (!matchingChat) {
        if (_newWsMessagesData) {
          matchingChat = _newWsMessagesData.find((message) => message.id === WSPinData?.pin_data?.id);
        }
      }
      if (matchingChat) {
        if (
          _newMessagesData &&
          _newMessagesData.messages &&
          Array.isArray(_newMessagesData.messages) &&
          _newMessagesData.messages.length > 0
        ) {
          _newMessagesData.messages.forEach((messageData) => {
            messageData.is_pinned = false;
          });
        }
        if (
          _newWsMessagesData &&
          _newWsMessagesData &&
          Array.isArray(_newWsMessagesData) &&
          _newWsMessagesData.length > 0
        ) {
          _newWsMessagesData.forEach((messageData) => {
            messageData.is_pinned = false;
          });
        }

        matchingChat.is_pinned = WSPinData?.pin_data?.is_pinned;

        Chat.messagesData = {
          ..._newMessagesData,
          messages: [..._newMessagesData.messages],
        };
      }
    }
  }, [Chat?.pinMessageData]);

  const handleRemoveFile = (filePreviewUrl) => {
    setFilePreview(filePreview.filter((file) => file.filePreview !== filePreviewUrl));
    setSelectedFile(selectedFile.filter((file) => file.filePreview !== filePreviewUrl));
  };

  const handleMessageInputPaste = (event) => {
    const clipboardItems = event.clipboardData.items;
    const dataTransfer = new DataTransfer();

    if (fileInputRef.current && fileInputRef.current.files.length > 0) {
      for (let i = 0; i < fileInputRef.current.files.length; i++) {
        dataTransfer.items.add(fileInputRef.current.files[i]);
      }
    }

    for (let i = 0; i < clipboardItems.length; i++) {
      const item = clipboardItems[i];

      if (item.kind === "file") {
        const file = item.getAsFile();
        if (file) {
          dataTransfer.items.add(file);
        }
      }
    }

    if (dataTransfer.files.length > 0) {
      fileInputRef.current.files = dataTransfer.files;

      handleFileChange({
        target: {
          files: dataTransfer.files,
        },
      });
    }
  };

  const [reactionChatId, setReactionChatId] = useState(null);
  const [reactionGroupReaction, setReactionGroupReaction] = useState(null);
  const toggleShowReactionOptions = (chat_id, group_reaction) => {
    setShowReactionOptionList((prevState) => {
      const newState = !prevState;
      return newState;
    });

    setReactionChatId(chat_id);
    setReactionGroupReaction(group_reaction);
  };

  let lastDate = "";

  const [showPinMessageModal, setShowPinMessageModal] = useState(false);
  const [pinMessageData, setPinMessageData] = useState(null);
  const onClickPin = async (conversation_id, chat_id) => {
    setPinMessageData({ conversation_id, chat_id });
    setShowPinMessageModal(true);
  };

  return (
    <main className='main'>
      {conversationModalShow && <ConversationModal showHideConversationModal={showHideConversationModal} />}
      {searchModalShow && (
        <SearchChatModal
          conversationData={Chat.conversationData?.conversation}
          showHideSearchmodal={showHideSearchmodal}
        />
      )}
      {viewMembersModal && (
        <ViewMembersModal
          conversationData={Chat.conversationData?.conversation}
          showHideViewMembersModal={showHideViewMembersModal}
          updateConversationData={Chat.updateConversationData}
          userDetails={userDetails}
          conversationUserPermission={Chat.conversationUserPermission}
        />
      )}
      {viewConversationFilesModal && (
        <ViewConversationFilesModal
          conversationData={Chat.conversationData?.conversation}
          showHideViewConversationFilesModal={showHideViewConversationFilesModal}
          userDetails={userDetails}
        />
      )}
      {viewActivityLogsShow && (
        <ViewActivityLogsModal
          conversationData={Chat.conversationData?.conversation}
          showHideViewActivityLogsModal={showHideViewActivityLogsModal}
          userDetails={userDetails}
        />
      )}
      {previewImage && (
        <PreviewImageModal previewImage={previewImage} isPreviewOpen={isPreviewOpen} closeModal={closeModal} />
      )}
      {openLightbox && (
        <ImageViewer openLightbox={openLightbox} setOpenLightbox={setOpenLightbox} lightboxSrcs={lightboxSrcs} />
      )}
      {viewChangePasswordModal && <ChangePasswordModal showHideChangePasswordModal={showHideChangePasswordModal} />}
      {editConversationModalShow && (
        <EditConversationModal
          showHideEditConversationModal={showHideEditConversationModal}
          conversationData={Chat.conversationData?.conversation}
        />
      )}
      {Chat.threadViewVisible && <ViewThreadModal userId={userId} handleImageClick={handleImageClick} />}
      {showPinMessageModal && (
        <PinModal setShowPinMessageModal={setShowPinMessageModal} pinMessageData={pinMessageData} />
      )}
      {showReactionOptionList && (
        <ReactionOptions
          receiver={true}
          chat_id={reactionChatId}
          reaction={reactionGroupReaction}
          userDetails={userDetails}
          toggleShowReactionOptions={toggleShowReactionOptions}
        />
      )}
      <div className='chat-container'>
        <div className='chat'>
          {/* SIDEBAR */}
          <SidebarHeader
            userDetails={userDetails}
            showHideChangePasswordModal={showHideChangePasswordModal}
            viewChangePasswordModal={viewChangePasswordModal}
          />
          <div
            className={`chat__sidebar ${
              Chat.isMobileMode && !Chat.isMobileChatDetailShow ? "chat__sidebar__expand" : "chat__sidebar__collapsed"
            }`}
          >
            <div className='inbox'>
              <div className='inbox__head'>
                <span className='inbox__heading'>
                  <img src={BuildhubTextLogo} alt='' className='inbox--headiing-image' />
                </span>
                {/* <span className='inbox__unread-message-count js-unread-message-count'>{totalUnreadCount} New</span> */}
                <button className='inbox__add-btn' onClick={showHideConversationModal}>
                  <i className='fa fa-plus inbox__add-icon'></i>
                </button>
              </div>
              <SearchInput
                showSearchResults={showSearchResults}
                hideSearchResults={hideSearchResults}
                isShowingSearchResults={isShowingSearchResults}
                setGlobalSearchResults={setGlobalSearchResults}
                setSearchQuery={setSearchQuery}
              />
              {!isShowingSearchResults && <ToggleSwitch />}
              <div className='inbox__messages' style={{ backgroundImage: `url(${buildHubLogo})` }}>
                <ul className='inbox__list' id='inbox__list'>
                  {isShowingSearchResults ? (
                    globalSearchResults.length > 0 ? (
                      globalSearchResults.map((searchResult, index) => (
                        <SearchResults searchResult={searchResult} key={index} searchQuery={searchQuery} />
                      ))
                    ) : (
                      <div className='loader-container'>
                        <span className='loader'></span>
                      </div>
                    )
                  ) : conversationProp.length > 0 ? (
                    <>
                      {pinnedConversations.map((chatDetail, index) => (
                        <ChatSideBarItem
                          chatDetail={chatDetail}
                          key={`pinned-${index}`}
                          linkConversation={linkConversation}
                          setConnectedRoom={setConnectedRoom}
                        />
                      ))}
                      {unpinnedConversations.map((chatDetail, index) => (
                        <ChatSideBarItem
                          chatDetail={chatDetail}
                          key={`unpinned-${index}`}
                          linkConversation={linkConversation}
                          setConnectedRoom={setConnectedRoom}
                        />
                      ))}
                    </>
                  ) : (
                    <div className='loader-container'>
                      <span className='loader'></span>
                    </div>
                  )}
                </ul>
              </div>

              <div className='copyright-footer'>
                <p>JJMJ &#169; 2024 All Rights Reserved. </p>
              </div>
            </div>
          </div>

          <div
            className={`chat__main ${
              Chat.isMobileMode && !Chat.isMobileChatDetailShow ? "chat__main__collapsed" : "chat__main__expand"
            }`}
          >
            <div className='message'>
              <div className='message__head' id='message__head'>
                {/* MSG HEAD */}
                <MessageHead
                  conversationData={Chat.conversationData}
                  setMobileChatDetailShow={Chat.setMobileChatDetailShow}
                  showHideSearchmodal={showHideSearchmodal}
                  showHideViewMembersModal={showHideViewMembersModal}
                  showHideViewConversationFilesModal={showHideViewConversationFilesModal}
                  showHideEditConversationModal={showHideEditConversationModal}
                  showHideViewActivityLogsModal={showHideViewActivityLogsModal}
                  conversationUserPermission={Chat.conversationUserPermission}
                />
              </div>
              {Chat.conversationData?.conversation?.pinned_message && (
                <PinnedMessage pinnedMessage={Chat.conversationData?.conversation?.pinned_message} />
              )}

              <div
                className='message__main'
                ref={Chat.messageMainRef}
                id='scrollableDiv'
                style={{
                  height: divHeight,
                  overflow: "auto",
                  display: "flex",
                  flexDirection: "column-reverse",
                  backgroundImage: `url(${buildHubLogo})`,
                }}
              >
                <InfiniteScroll
                  dataLength={allItems.length}
                  next={handleViewMore}
                  hasMore={isAbleToLoad}
                  scrollableTarget='scrollableDiv'
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                  inverse={true}
                  initialScrollY={0}
                  scrollThreshold={0}
                >
                  {allItems?.length > 0 ? (
                    <>
                      {/* MSG CONTAINER */}
                      {allItems.map((chat, index) => {
                        const currentDate = formatDate(chat.createdAt);
                        const showSeparator = currentDate !== lastDate;
                        lastDate = currentDate;
                        return (
                          <React.Fragment key={index}>
                            {showSeparator && <MessageSeparator date={currentDate} />}
                            {renderChats(
                              chat,
                              index,
                              userId,
                              userDetails,
                              handleImageClick,
                              toggleShowChatReply,
                              Chat.setChatReplyData,
                              toggleShowReactionOptions,
                              onClickPin
                            )}
                          </React.Fragment>
                        );
                      })}
                      {Chat.wsMessagesData.map((chat, index) => {
                        const currentDate = formatDate(chat.createdAt);
                        const showSeparator = currentDate !== lastDate;
                        lastDate = currentDate;
                        return (
                          <React.Fragment key={index}>
                            {showSeparator && <MessageSeparator date={currentDate} />}
                            {renderChats(
                              chat,
                              index,
                              userId,
                              userDetails,
                              handleImageClick,
                              toggleShowChatReply,
                              Chat.setChatReplyData,
                              toggleShowReactionOptions,
                              onClickPin
                            )}
                          </React.Fragment>
                        );
                      })}
                      {/* MSG CONTAINER */}
                      {!isAtBottom && (
                        <button className='scroll-to-bottom' onClick={scrollToBottom}>
                          <i className='fa fa-chevron-down'></i>
                        </button>
                      )}
                    </>
                  ) : (
                    <>
                      <div className='loader-container-message--main'>
                        <h2>Empty Chat</h2>
                        <small>Choose a conversation or send something!</small>
                        {/* <span className='loader-message--main'></span> */}
                      </div>
                    </>
                  )}
                  {Chat.isShowingTyping && Chat.typingData ? (
                    <MessageTyping typingData={Chat.typingData} userDetails={userDetails} />
                  ) : null}
                </InfiniteScroll>
              </div>

              {/* CHAT INPUT REPLY */}
              {Chat.chatReplyData !== null && (
                <ChatInputReply
                  toggleShowChatReply={toggleShowChatReply}
                  chatReplyData={Chat.chatReplyData}
                  setChatReplyData={Chat.setChatReplyData}
                  userDetails={userDetails}
                />
              )}
              {/* CHAT INPUT REPLY */}
              {showSuggestions && (
                <div className='mention-container'>
                  <ul className='mention-suggested-users'>
                    {showSuggestions && (
                      <>
                        {/* "Mention All" option */}
                        <li
                          className='mention-users-item'
                          key='mention-all'
                          onClick={() =>
                            handleSuggestionClick({
                              id: "all",
                              name: "All",
                            })
                          }
                        >
                          <span>
                            <strong>All</strong>
                          </span>
                        </li>

                        {/* Render other member suggestions */}
                        {conversationMemberSuggestions.map((member, index) => (
                          <li
                            className='mention-users-item'
                            key={index}
                            onClick={() =>
                              handleSuggestionClick({
                                id: member.user.id,
                                name: member.user.display_name,
                              })
                            }
                          >
                            <img src={emptyImage} className='mention-user--profile-photo' />
                            <span>{member.user.display_name}</span>
                          </li>
                        ))}
                      </>
                    )}
                  </ul>
                </div>
              )}

              <div className='message__input-container'>
                <div className='user-message-attachment-container'>
                  {filePreview.map((_filePreview, index) => {
                    if (_filePreview.type.includes("image/")) {
                      return (
                        <div key={index}>
                          <img src={_filePreview.filePreview} alt='' />
                          <button
                            className='message__btn-remove-file'
                            onClick={() => handleRemoveFile(_filePreview.filePreview)}
                          >
                            <i className='fa fa-times'></i>
                          </button>{" "}
                        </div>
                      );
                    } else {
                      return (
                        <div key={index}>
                          <div className='message__file-name'>{_filePreview.file.name}</div>
                          <button
                            className='message__btn-remove-file'
                            onClick={() => handleRemoveFile(_filePreview.filePreview)}
                          >
                            <i className='fa fa-times'></i>
                          </button>
                        </div>
                      );
                    }
                  })}
                </div>
                <div className='message-input----container'>
                  <textarea
                    style={{ height: "45px" }}
                    className='message__input js-message--input msg-tool'
                    placeholder={
                      !Chat.isPermittedToChat
                        ? "You are restricted from chatting in this conversation"
                        : "Write a message..."
                    }
                    disabled={!Chat.isPermittedToChat ? true : false}
                    value={messageContent}
                    onChange={(e) => handleMessageInput(e)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && e.shiftKey && Chat.isPermittedToChat) {
                        e.preventDefault();
                        setMessageContent((prevContent) => prevContent + "\n");
                      } else if (e.key === "Enter" && Chat.isPermittedToChat && !Chat.isMobileMode) {
                        e.preventDefault();
                        handleSendMessage();
                      } else {
                        handleCurrentlyTyping();
                      }
                    }}
                    onPaste={handleMessageInputPaste}
                  />

                  <div className='message-input----container-actions'>
                    <input
                      type='file'
                      id='fileInput'
                      className='message__file-input'
                      onChange={handleFileChange}
                      ref={fileInputRef}
                      multiple
                    />

                    <button
                      className='message__btn-file js-file-upload--btn msg-tool'
                      onClick={() => document.getElementById("fileInput").click()}
                    >
                      <i className='fa fa-paperclip message__btn-file-icon'></i>
                    </button>

                    <button
                      className='message__btn-send js-send-message--btn msg-tool'
                      data-receiver=''
                      data-cid=''
                      onClick={() => {
                        if (Chat.isPermittedToChat) {
                          handleSendMessage();
                        }
                      }}
                    >
                      <i className='fa fa-paper-plane message__btn-send-icon'></i>
                      <img src={loader2} className='loader-msg' />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

function renderChats(
  item,
  index,
  currentUserId,
  userDetails,
  handleImageClick,
  toggleShowChatReply,
  setChatReplyData,
  toggleShowReactionOptions,
  onClickPin
) {
  const { createdAt } = item;

  if (item.sender_id) {
    const { sender_id } = item;
    return (
      <React.Fragment key={createdAt + index}>
        {sender_id === currentUserId ? (
          <MessageSender
            messageDetail={item}
            handleImageClick={handleImageClick}
            toggleShowChatReply={toggleShowChatReply}
            setChatReplyData={setChatReplyData}
            userDetails={userDetails}
            toggleShowReactionOptions={toggleShowReactionOptions}
            onClickPin={onClickPin}
          />
        ) : (
          <MessageReceiver
            messageDetail={item}
            handleImageClick={handleImageClick}
            toggleShowChatReply={toggleShowChatReply}
            setChatReplyData={setChatReplyData}
            userDetails={userDetails}
            toggleShowReactionOptions={toggleShowReactionOptions}
            onClickPin={onClickPin}
          />
        )}
      </React.Fragment>
    );
  }
}

export default Main;
