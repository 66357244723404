import React, { useState } from "react";
import "../../../styles/FormsSearch.css";

function FormsSearch({ handleSearch }) {
  const [query, setQuery] = useState("");

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearch(query);
  };

  return (
    <div className='formsSearch-container'>
      <form onSubmit={handleSubmit} className='formsSearch-form'>
        <input
          type='text'
          placeholder='Search...'
          value={query}
          onChange={handleInputChange}
          className='formsSearch-input'
        />
        <button type='submit' className='formsSearch-button'>
          🔍
        </button>
      </form>
    </div>
  );
}

export default FormsSearch;
