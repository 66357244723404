import { useState, useEffect, useContext } from "react";
import { common } from "../util/common";
import showSwal from "../components/swal";
import ApiService from "../api/apiService";
import { useNavigate } from "react-router-dom";
import spinner from "../images/loader.gif";
import Header from "../components/header";
import MainProfile from "../components/main-profile";
import verifyToken from "../hooks/verifyToken";
import { ProfileContext } from "../context/contexts";
import { saveUserDetailsToLocalStorage } from "../hooks/accountHandler";

function ProfilePage() {
  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState();
  const [userDetailsApi, setUserDetailsApi] = useState(null);
  const [userDisplayName, setUserDisplayName] = useState(null);

  useEffect(() => {
    const getUserDetails = () => {
      const userDetails = localStorage.getItem("userDetails");
      const _userDetails = JSON.parse(userDetails);

      setUserDetails(_userDetails);
    };
    const checkLoggedIn = async () => {
      const isLoggedIn = await verifyToken();
      if (!isLoggedIn) {
        navigate("/login");
      } else {
        getUserDetails();
      }
    };

    checkLoggedIn();
  }, []);

  useEffect(() => {
    const getUserDetailApi = async () => {
      const userDetailResponse = await ApiService.account.getUserDetails();
      const { success, user_details } = userDetailResponse;
      if (!success) return showSwal(common.errors.FETCH_USERDATA_ERROR);
      setUserDetailsApi(user_details);
      setUserDisplayName(user_details.display_name);
    };

    getUserDetailApi();
  }, []);

  const handleDisplayNameChange = (value) => {
    setUserDisplayName(value);
  };

  const handleSubmitChanges = async (imageData) => {
    const formData = new FormData();
    const payload = {
      display_name: userDisplayName,
    };
    formData.append("profile_photo", imageData);
    formData.append("data", JSON.stringify(payload));

    const updateResponse = await ApiService.account.updateUserDetails(formData);
    const { success, updated_user } = updateResponse;
    if (!success) return showSwal(common.errors.UPDATE_USERDATA_ERROR);

    const newUserDetails = {
      id: updated_user.id,
      display_name: updated_user.display_name,
    };
    saveUserDetailsToLocalStorage(newUserDetails);
    showSwal(common.success.UPDATE_USER_DETAILS).then(() => {
      window.location.href = "/";
    });
  };

  const inProfile = true;

  return (
    <div className='page'>
      <ProfileContext.Provider
        value={{
          userDetailsApi: userDetailsApi,
          handleDisplayNameChange: handleDisplayNameChange,
          userDisplayName: userDisplayName,
          handleSubmitChanges,
        }}
      >
        <Header userDetails={userDetails} inProfile={inProfile} />
        <MainProfile />
      </ProfileContext.Provider>
    </div>
  );
}

export default ProfilePage;
