import React, { useState, useEffect, useContext } from "react";
import Table from "./reusable/table";
import ApiService from "../../api/apiService";
import showSwal from "../swal";
import { common } from "../../util/common";
import MyFormitem from "./reusable/my-form-item";
import ApprovalItemList from "./reusable/approval-item-list.jsx";
import FormsSearch from "./reusable/forms-search.jsx";

function ApprovalRequests() {
  const [formData, setFormData] = useState([]);
  const [originalRequestsData, setOriginalRequestData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const getForms = async () => {
      const myForms = await ApiService.forms.getApprovalRequestsList();
      const { success, requests } = myForms;
      if (!success) return showSwal(common.errors.GETTING_APPROVAL_LIST_ERROR);
      setOriginalRequestData(requests);
      setFormData(requests);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };

    getForms();
  }, []);

  const handleSearch = (query) => {
    if (query.trim() === "") {
      setFormData(originalRequestsData);
    } else {
      const filteredData = originalRequestsData.filter(
        (requestData) =>
          requestData?.reference_id?.toLowerCase().includes(query.toLowerCase()) ||
          requestData?.request_type?.toLowerCase().includes(query.toLowerCase()) ||
          requestData?.createdAt?.toLowerCase().includes(query.toLowerCase()) ||
          requestData?.requester_user_detail?.first_name?.toLowerCase().includes(query.toLowerCase()) ||
          requestData?.requester_user_detail?.last_name?.toLowerCase().includes(query.toLowerCase())
      );
      setFormData(filteredData);
    }
  };

  return (
    <div>
      <div className='request-main-title'>
        <h3>My Pending Approvals</h3>
      </div>
      <FormsSearch handleSearch={handleSearch} />
      {isLoading ? (
        <div className='loader--container'>
          <div className='loader--'></div>
        </div>
      ) : (
        <div className='request-main---container'>
          {formData.map((data, index) => (
            <ApprovalItemList data={data} key={index} />
          ))}
        </div>
      )}
    </div>
  );
}

export default ApprovalRequests;
