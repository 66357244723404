import { useState, useEffect, useContext } from "react";
import Header from "../components/header";
import ApiService from "../api/apiService";
import showSwal from "../components/swal";
import { common } from "../util/common";
import RequestIndex from "../components/requests";
import "../styles/requests.css";

function MyRequests() {
  const [userDetails, setUserDetails] = useState();
  const [userDetailsApi, setUserDetailsApi] = useState(null);
  const [userDisplayName, setUserDisplayName] = useState(null);

  useEffect(() => {
    const getUserDetailApi = async () => {
      const userDetailResponse = await ApiService.account.getUserDetails();
      const { success, user_details } = userDetailResponse;
      if (!success) return showSwal(common.errors.FETCH_USERDATA_ERROR);
      setUserDetailsApi(user_details);
      setUserDisplayName(user_details.display_name);
    };

    getUserDetailApi();
  }, []);

  return (
    <>
      <Header userDetails={userDetailsApi} inProfile={false} />
      <RequestIndex userDetails={userDetailsApi} />
    </>
  );
}

export default MyRequests;
