import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../../styles/view-request-detail.css";
import UserDetail from "./reusable/user-detail-request-item";
import RequestDetailItem from "./reusable/request-detail-item";
import ApiService from "../../api/apiService";
import showSwal from "../swal";
import { common } from "../../util/common";
import { convertTo12HourPlusDateFormat, formatToPeso } from "../../util/helperFunctions";

function RequestDetail({ handleShowApproversModal, setApproversData, userDetails }) {
  const linkData = useLocation();
  const [isLoading, setLoading] = useState(true);
  const [requisitionData, setRequisitionData] = useState(null);
  const [isUserApprover, setUserApprover] = useState(false);
  const [returnUrl, setReturnUrl] = useState("/");

  const handleApproveDenyRequest = async (status) => {
    const apiResponse = await ApiService.forms.approveDenyRequest(
      requisitionData?.requisition?.form_id,
      requisitionData?.requisition?.form_request_detail?.id,
      status
    );
    const { success } = apiResponse;

    if (!success) return showSwal(common.errors.APPROVE_REQUEST_ERROR);

    window.location.reload();
  };

  useEffect(() => {
    const getFormRequest = async (requestType, requestLink) => {
      const response = await ApiService.forms.getFormRequestDetail(requestType, requestLink);
      const { success, request } = response;
      if (!success) return showSwal(common.errors.GETTING_REQUESTS_DETAIL_ERROR);

      setRequisitionData(request);
      setApproversData(request?.approval);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };
    const searchParams = new URLSearchParams(linkData.search);
    const requestType = searchParams.get("t");
    const requestLinkId = searchParams.get("r");
    const _returnUrl = searchParams.get("r_url");
    if (requestType !== "" && requestType !== null && requestLinkId !== "" && requestLinkId !== null) {
      setReturnUrl(_returnUrl);
      getFormRequest(requestType, requestLinkId);
    } else {
      setReturnUrl("/");
    }
  }, [linkData.search]);

  useEffect(() => {
    if (userDetails && requisitionData) {
      const { approval, overall_approval_status } = requisitionData;
      const { first_level_approval, second_level_approval } = approval;

      const isFirstLevelApprover = first_level_approval.find((approval) => approval.id === userDetails.id);
      const isSecondLevelApprover = second_level_approval.find((approval) => approval.id === userDetails.id);

      if (isFirstLevelApprover || isSecondLevelApprover) {
        if (isFirstLevelApprover && !isFirstLevelApprover.has_voted && !overall_approval_status) {
          setUserApprover(true);
        }
        if (isSecondLevelApprover && !isSecondLevelApprover.has_voted && !overall_approval_status) {
          setUserApprover(true);
        }
      }
    }
  }, [userDetails, requisitionData]);

  return (
    <>
      {isLoading ? (
        <div className='loader--container'>
          <div className='loader--'></div>
        </div>
      ) : requisitionData ? (
        <div className='view-request-detail-container'>
          <a href={returnUrl}>
            <div className='go-back'>
              <i className='fa fa-arrow-left fa-3x' aria-hidden='true'></i>
              <span>Go Back</span>
            </div>
          </a>
          <div className='request-detail-row'>
            <div className='request-detail--card'>
              <div className='requester-information'>
                <div className='approval-information'>
                  <h1>Approval Information</h1>
                  <div className='information---container' onClick={() => handleShowApproversModal()}>
                    <i className='fa fa-info fa-2x' aria-hidden='true'></i>
                  </div>
                  <div
                    className={`approval-text ${
                      requisitionData?.overall_approval_status ? "approved-status" : "pending-status"
                    }`}
                  >
                    <span>{requisitionData?.overall_approval_status ? "APPROVED" : "PENDING"}</span>
                  </div>
                </div>
                <UserDetail
                  fieldName={"Initial Approval Count Needed: "}
                  fieldValue={requisitionData?.approval?.initial_approval_needed_count}
                />
                <div className='user-detail'>
                  <UserDetail
                    fieldName={"Executive Approval Count Needed: "}
                    fieldValue={`${requisitionData?.approval?.executive_approval_needed_count}`}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='request-detail-row'>
            <div className='request-detail--card'>
              <div className='requester-information'>
                <UserDetail
                  fieldName={"Unique ID: "}
                  fieldValue={requisitionData?.requisition?.form_request_detail?.reference_id}
                />
                <div className='user-detail'>
                  <UserDetail
                    fieldName={"Name: "}
                    fieldValue={`${requisitionData?.requisition?.user_detail?.first_name} ${requisitionData?.requisition?.user_detail?.last_name}`}
                  />
                </div>
                <div className='user-detail'>
                  <UserDetail
                    fieldName={"Email Address: "}
                    fieldValue={requisitionData?.requisition?.user_detail?.email_address}
                  />
                </div>
                <div className='user-detail'>
                  <UserDetail
                    fieldName={"Company: "}
                    fieldValue={requisitionData?.requisition?.pr_company_detail?.name}
                  />
                </div>
                <div className='user-detail'>
                  <UserDetail
                    fieldName={"Date & Time of Request: "}
                    fieldValue={convertTo12HourPlusDateFormat(requisitionData?.requisition?.createdAt)}
                  />
                </div>
              </div>
            </div>
            <div className='request-detail--card'>
              <div className='user-detail'>
                <UserDetail
                  fieldName={"Type of Request: "}
                  fieldValue={requisitionData?.requisition?.pr_request_detail?.name}
                />
              </div>
              <div className='user-detail'>
                <UserDetail
                  fieldName={"Urgent: "}
                  fieldValue={`${requisitionData?.requisition?.urgent ? "Yes" : "No"}`}
                />
              </div>
              <div className='user-detail'>
                <UserDetail
                  fieldName={"Recommended Supplier: "}
                  fieldValue={requisitionData?.requisition?.recommended_supplier}
                />
              </div>
              <div className='user-detail'>
                <UserDetail
                  fieldName={"Date Needed: "}
                  fieldValue={convertTo12HourPlusDateFormat(requisitionData?.requisition?.date_needed)}
                />
              </div>
              <div className='user-detail'>
                <UserDetail
                  fieldName={"Total Cost of Request: "}
                  fieldValue={formatToPeso(requisitionData?.requisition?.total_cost)}
                />
              </div>
            </div>
          </div>

          <div className='request-detail-row'>
            <div className='request-detail--card request-detail--container--0'>
              <div className='requester-information request-detail--container'>
                {requisitionData?.requisition?.pr_purchases_detail?.length > 0 ? (
                  requisitionData?.requisition?.pr_purchases_detail.map((prDetail, index) => (
                    <RequestDetailItem key={index} prDetail={prDetail} />
                  ))
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
          {isUserApprover && (
            <div className='approver--actions'>
              <button className='action-btn approve-btn' onClick={(e) => handleApproveDenyRequest(true)}>
                Approve Request
              </button>
              <button className='action-btn deny-btn' onClick={(e) => handleApproveDenyRequest(false)}>
                Deny Request
              </button>
            </div>
          )}
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}

export default RequestDetail;
