import React, { useState } from "react";
import emptyImage from "../images/empty.jpg";
import ApiService from "../api/apiService";
import showSwal from "./swal";
import { common } from "../util/common";
import loader_2 from "../images/loader_2.gif";

function ChangePasswordModal({ showHideChangePasswordModal }) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleChangePassword = async () => {
    if (isLoading) return;
    if (newPassword !== confirmPassword) return setError("New Password & Confirm Password don't matched!");
    if (!oldPassword || !newPassword || !confirmPassword) return setError("Please fill out the inputs!");

    setIsLoading(true);
    const response = await ApiService.account.changePassword(oldPassword, newPassword);

    const { success, message } = response;
    if (!success) {
      setError(message);
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    showHideChangePasswordModal();
    showSwal(common.success.CHANGED_PASSWORD);
  };
  return (
    <div className='modal-container'>
      <div className='modal-dialog'>
        <button className='close-btn' onClick={showHideChangePasswordModal}>
          ✕
        </button>
        <h3>Change User Password</h3>
        {errors !== "" && (
          <div className='error-container'>
            <i class='fa fa-info' aria-hidden='true'></i>
            <div className='error-msg'>
              <h4>{errors}</h4>
            </div>
          </div>
        )}
        <div className='modal-change-password-container'>
          <div>
            <h4 htmlFor='' className='modal-change-password-label'>
              Old Password <span className='text-red'>*</span>
            </h4>
            <input
              type='password'
              className='modal-change-password-input'
              name=''
              id=''
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </div>
          <div>
            <h4 htmlFor='' className='modal-change-password-label'>
              New Password <span className='text-red'>*</span>
            </h4>
            <input
              type='password'
              className='modal-change-password-input'
              name=''
              id=''
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div>
            <h4 htmlFor='' className='modal-change-password-label'>
              Confirm Password <span className='text-red'>*</span>
            </h4>
            <input
              type='password'
              className='modal-change-password-input'
              name=''
              id=''
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <div>
            <button type='button' className='change-pw-btn' onClick={handleChangePassword}>
              {isLoading ? <img src={loader_2} alt='' /> : "Change Password"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePasswordModal;
