import React, { useState, useContext, useEffect } from "react";
import { ChatContext, WebSocketContext } from "../context/contexts";
import { convertTo12HourPlusDateFormat, trimWord, formatBoldText } from "../util/helperFunctions";
import ApiService from "../api/apiService";
import showSwal from "./swal";
import { common } from "../util/common";

function PinnedMessage(props) {
  const { pinnedMessage } = props;
  const { content, id, user, conversation_id, updatedAt, is_pinned } = pinnedMessage;
  const Chat = useContext(ChatContext);
  const WS = useContext(WebSocketContext);

  const handlePinClick = async (chatId) => {
    try {
      const messagePositionResponse = await ApiService.message.getMessagePosition(conversation_id, chatId);
      const { success, response } = messagePositionResponse;

      if (!success) {
        showSwal(common.errors.FETCH_MSG_POSITION_ERROR);
        return;
      }
      const { thread, query, index } = response;

      const { thread_reply, message_id } = thread;

      if (thread_reply) {
        Chat.handleThreadViewMsgId(message_id);
      } else {
        Chat.reSetConversationDetail(conversation_id, query, function callback() {
          setTimeout(() => {
            const msgView = document.getElementById(chatId);
            if (msgView) {
              const coloredElement = msgView.querySelector("span");

              coloredElement.classList.add("pulse");
              coloredElement.style.backgroundColor = "#ff0002";
              msgView.scrollIntoView({ behavior: "smooth" });

              setTimeout(() => {
                coloredElement.classList.remove("pulse");
                if (coloredElement.classList.contains("message__item-text--receiver")) {
                  coloredElement.style.backgroundColor = "#fff";
                } else {
                  coloredElement.style.backgroundColor = "#9d0001";
                }
              }, 2500);
            }
          }, 1000);
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleRemovePinClick = async (e) => {
    const response = await ApiService.message.pinMessage(conversation_id, id);
    if (response && response.success) {
      WS.websocketInstance.pinMessage(conversation_id, id, response.message);
    }
  };

  return (
    is_pinned && (
      <div className='pinned-message-container' onClick={() => handlePinClick(id)}>
        <span className='pin-icon'>📌</span>
        <div>
          <h5>{formatBoldText(trimWord(content, 80))}</h5>
          <small>Pinned {convertTo12HourPlusDateFormat(updatedAt)}</small>
        </div>
        <button
          className='pinned-remove-btn'
          onClick={(e) => {
            e.stopPropagation();
            handleRemovePinClick();
          }}
        >
          X
        </button>
      </div>
    )
  );
}

export default PinnedMessage;
