import { useRouteError } from "react-router-dom";
import "./styles/error-404.css";
import buildhubBg from "./images/bg.png";

export default function Error404Page() {
  const error = useRouteError();
  console.error(error);

  return (
    <div className='error-container-page' style={{ backgroundImage: `url(${buildhubBg})` }}>
      <div className='Absolute-Center'>
        <h1 className='error-container-title'>404. Are you lost?</h1>
        <h2 className='error-container-message'>Please contact support.</h2>
      </div>
      <div className='footer-container'>
        <p className='footer-text'>Buildhub © 2024 All Rights Reserved.</p>
      </div>
    </div>
  );
}
