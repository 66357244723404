import SwalComponent from "../components/swal";
import getCookie from "./getToken";

export const saveTokenToCookies = (token, isRememberMe) => {
  const date = new Date();
  if (isRememberMe) {
    date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);
  } else {
    date.setTime(date.getTime() + 2 * 60 * 60 * 1000);
  }
  const expires = "expires=" + date.toUTCString();
  document.cookie = "token=" + token + ";" + expires + ";path=/";
};

export const saveUserDetailsToLocalStorage = (userDetails) => {
  localStorage.setItem("userDetails", JSON.stringify(userDetails));
};

export const userLogout = () => {
  document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  localStorage.removeItem("userDetails");
};

export const expiredSession = () => {
  // const token = getCookie("token");
  // if (!token) {
  //   SwalComponent("EXPIRED SESSION", "info", "Your session has expired, please login again.", "OK");
  // }
  window.location.href = "/";
};
