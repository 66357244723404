import "../../../styles/requests.css";
import { convertTo12HourPlusDateFormat } from "../../../util/helperFunctions";
function MyFormitem({ data }) {
  const handleViewBtnClick = (form_id) => {
    window.location.href = `/requests?p=new-request&f=${form_id}`;
  };
  return (
    <div className='request-item--list-container' onClick={() => handleViewBtnClick(data?.form_details?.id)}>
      <div className='request-item--row'>
        <div className='request--item-logo'>
          <i className='fa fa-file-text-o fa-4x' aria-hidden='true'></i>
        </div>
        <div className=''>
          <div className='request-ref-no'>
            <h1>{data?.form_details?.name}</h1>
          </div>
          <div className='request--detail'>
            <div className='request--detail-item'>
              <span>Form Type:&nbsp;</span>
              <span>{data?.form_details?.type}</span>
            </div>
            <div className='request--detail-item'>
              <span className=''>Date & Time:&nbsp;</span>
              <span className=''>{convertTo12HourPlusDateFormat(data?.createdAt)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyFormitem;
