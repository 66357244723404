import React, { useState, useContext } from "react";
import emptyImage from "../images/empty.jpg";
import ApiService from "../api/apiService";
import showSwal from "./swal";
import { common } from "../util/common";
import { ChatContext } from "../context/contexts";
import { highlightText } from "../util/helperFunctions";

function SearchChatModal({ conversationData, showHideSearchmodal }) {
  const Chat = useContext(ChatContext);
  const conversation_id = conversationData?.id;
  const conversation_name = conversationData?.name;
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = async () => {
    if (searchInput.length > 0) {
      const queryResults = await ApiService.message.searchMessage({ keyword: searchInput }, conversation_id);
      const { success, result } = queryResults;
      if (!success) showSwal(common.errors.SEARCH_MESSAGE_ERROR);
      setSearchResults(result);
    }
  };

  const handleResultClick = async (chatId) => {
    try {
      const messagePositionResponse = await ApiService.message.getMessagePosition(conversation_id, chatId);
      const { success, response } = messagePositionResponse;

      if (!success) {
        showSwal(common.errors.FETCH_MSG_POSITION_ERROR);
        return;
      }
      const { thread, query, index } = response;

      const { thread_reply, message_id } = thread;

      if (thread_reply) {
        Chat.handleThreadViewMsgId(message_id);
      } else {
        Chat.reSetConversationDetail(conversation_id, query, function callback() {
          setTimeout(() => {
            const msgView = document.getElementById(chatId);
            if (msgView) {
              const coloredElement = msgView.querySelector("span");

              coloredElement.classList.add("pulse");

              msgView.scrollIntoView({ behavior: "smooth" });

              setTimeout(() => {
                coloredElement.classList.remove("pulse");
              }, 2500);
            }
          }, 1000);
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className='modal-container'>
      <div className='modal-dialog'>
        <button className='close-btn' onClick={showHideSearchmodal}>
          ✕
        </button>
        <h3>Search chats in {conversation_name}...</h3>
        <div className='modal-search'>
          <input
            type='text'
            className='modal-search-input'
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleSearch();
              }
            }}
            placeholder='Type to search...'
          />
          <div className='modal-search-input-icon'>
            <i className='fa fa-search'></i>
          </div>
          <button className='search-btn' onClick={handleSearch}>
            Search
          </button>
        </div>
        <ul className='search-results'>
          {searchResults.length > 0 ? (
            searchResults.map((result, index) => {
              const profileImage =
                result?.user?.profile_photo === "" || result?.user?.profile_photo === null
                  ? emptyImage
                  : `${process.env.REACT_APP_IMAGES_DATA_RESOURCES_URL}/${result?.user?.profile_photo}`;
              return (
                <li key={index} onClick={(e) => handleResultClick(result?.id)}>
                  <div className='result-container'>
                    <img src={profileImage} className='result-chat-image' alt='' />
                    <h4 className='result-chat-username'>{result?.user?.display_name}:&nbsp;</h4>
                    <span className='result-chat-message'>{highlightText(result?.content, searchInput)}</span>
                  </div>
                </li>
              );
            })
          ) : (
            <li>
              <span className='no-chats-found'>NO CHATS FOUND</span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

export default SearchChatModal;
