import { useContext } from "react";
import { ProfileContext } from "../../context/contexts";
function ProfileInput(props) {
  const { id, label, disabled, value, setter } = props;
  const profileDetail = useContext(ProfileContext);

  let displayedValue = "";
  if (profileDetail && profileDetail.userDetailsApi) {
    for (const [key, _value] of Object.entries(profileDetail.userDetailsApi)) {
      if (key === id) {
        displayedValue = _value;
      }
    }
  }

  return (
    <>
      <label htmlFor={id}>{label}</label>
      {id === "display_name" ? (
        <input
          type='text'
          className='profile-text-input'
          id={id}
          disabled={disabled}
          value={profileDetail.userDisplayName}
          onChange={(e) => profileDetail.handleDisplayNameChange(e.target.value)}
        />
      ) : (
        <input type='text' className='profile-text-input' id={id} disabled={disabled} value={displayedValue} />
      )}
      {disabled && <span className='gray-text'>Contact support for information updates.</span>}
    </>
  );
}
export default ProfileInput;
