import React, { useState, useEffect, useContext } from "react";
import "../styles/conversation-modal.css";
import ApiService from "../api/apiService";
import showSwal from "./swal";
import { common } from "../util/common";
import { WebSocketContext } from "../context/contexts";

function EditConversationModal(props) {
  const WS = useContext(WebSocketContext);

  const { showHideEditConversationModal, conversationData } = props;
  const FINAL_RESOURCES_URL = `${process.env.REACT_APP_IMAGES_DATA_RESOURCES_URL}/${conversationData?.image}`;

  const [conversationName, setConversationName] = useState(conversationData?.name);

  const [imageData, setImageData] = useState(FINAL_RESOURCES_URL);

  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    const payload = {
      name: conversationName,
    };
    formData.append("conversation_image", image ? imageData : null);
    formData.append("data", JSON.stringify(payload));

    const _conversation = await ApiService.conversation.updateConversationDetail(formData, conversationData?.id);

    const { success, conversation_action } = _conversation;
    if (success) {
      WS.websocketInstance.sendMessage({ message: conversation_action, type: "user-actions-chat" });
      showSwal(common.success.CONVERSATION_UPDATED);
    } else {
      showSwal(common.errors.UPDATE_CONVERSATION_ERROR);
    }

    showHideEditConversationModal();
  };

  useEffect(() => {
    if (!image) {
      setImagePreview(imageData);
      return;
    }

    const objectUrl = URL.createObjectURL(image);
    setImagePreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [image]);

  return (
    <div className='modal-container'>
      <div className='modal-dialog'>
        <button className='close-btn' onClick={showHideEditConversationModal}>
          ✕
        </button>
        <form onSubmit={handleSubmit}>
          <div className='form-group edit-conversation-form-group'>
            <label>Conversation Image</label>
            <div className='container'>
              <div className='edit-conversation-image-container'>
                <img src={imagePreview} className='edit-conversation-image' />
              </div>
              <div className='edit-conversation-input-container'>
                <input
                  type='file'
                  onChange={(e) => {
                    setImage(e.target.files[0]);
                    setImageData(e.target.files[0]);
                  }}
                />
              </div>
            </div>
          </div>
          <div className='form-group'>
            <label>Conversation Name</label>
            <input
              type='text'
              value={conversationName}
              onChange={(e) => setConversationName(e.target.value)}
              required
            />
          </div>
          <div className='form-group'>
            <button type='submit' className='submit-btn'>
              Save Conversation
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditConversationModal;
