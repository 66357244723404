import React, { useState, useEffect, useContext } from "react";
import ApiService from "../../api/apiService.js";
import showSwal from "../swal.jsx";
import { common } from "../../util/common.js";
import RequestHistoryItem from "./reusable/request-history-item.jsx";
import FormsSearch from "./reusable/forms-search.jsx";

function RequestsHistory() {
  const [formData, setFormData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const getForms = async () => {
      const myForms = await ApiService.forms.getRequestsHistory();
      const { success, requests } = myForms;
      if (!success) return showSwal(common.errors.GETTING_APPROVAL_LIST_ERROR);
      setFormData(requests);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };

    getForms();
  }, []);

  const handleSearch = async (query) => {
    setLoading(true);
    const searchResults = await ApiService.forms.getRequestsHistory(query);
    const { success, requests } = searchResults;
    if (!success) return showSwal(common.errors.GETTING_APPROVAL_LIST_ERROR);
    setFormData(requests);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <div>
      <div className='request-main-title'>
        <h3>Requests History</h3>
      </div>
      <FormsSearch handleSearch={handleSearch} />
      {isLoading ? (
        <div className='loader--container'>
          <div className='loader--'></div>
        </div>
      ) : (
        <div className='request-main---container'>
          {formData.map((data) => (
            <RequestHistoryItem data={data} />
          ))}
        </div>
      )}
    </div>
  );
}

export default RequestsHistory;
