import React, { useState, useEffect, useContext } from "react";
import Table from "./reusable/table";
import ApiService from "../../api/apiService";
import showSwal from "../swal";
import { common } from "../../util/common";
import MyFormitem from "./reusable/my-form-item";

function MyForms() {
  const [formData, setFormData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const getForms = async () => {
      const myForms = await ApiService.forms.getMyForms();
      const { success, forms } = myForms;
      if (!success) return showSwal(common.errors.FETCH_MY_FORMS_ERROR);
      setFormData(forms);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };

    getForms();
  }, []);

  return (
    <div>
      <div className='request-main-title'>
        <h3>My Forms</h3>
      </div>
      {isLoading ? (
        <div className='loader--container'>
          <div className='loader--'></div>
        </div>
      ) : (
        <div className='request-main---container'>
          {formData.map((data, index) => (
            <MyFormitem data={data} key={index} />
          ))}
        </div>
      )}
    </div>
  );
}

export default MyForms;
