import React, { useState, useContext, useEffect } from "react";
import "./SidebarHeader.css"; // Import CSS for styling
import NotificationBell from "./reusable/notification-bell";
import BuildHubLogo from "../images/buildhub.png";
import BuildHubLogoSmall from "../images/buildhub-small.png";
import BChatLogo from "../images/buildhub-chat-logo.png";
import profileFiller from "../images/empty.jpg";

import { userLogout } from "../hooks/accountHandler";
import { useNavigate } from "react-router-dom";
import { ChatContext, WebSocketContext } from "../context/contexts";

function SidebarHeader(props) {
  const Chat = useContext(ChatContext);
  const WS = useContext(WebSocketContext);
  const navigate = useNavigate();

  const { userDetails, showHideChangePasswordModal } = props;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const userProfile =
    userDetails && userDetails.profile_photo && userDetails.profile_photo !== null && userDetails.profile_photo !== ""
      ? `${process.env.REACT_APP_IMAGES_DATA_RESOURCES_URL}/${userDetails.profile_photo}`
      : profileFiller;
  const handleLogout = () => {
    userLogout();
    WS.websocketInstance.logout();
    navigate("/login");
  };

  return (
    <div className={`sidebar-header ${Chat.isMobileMode && Chat.isMobileChatDetailShow ? "sidebar-header-hide" : ""}`}>
      <div className='sidebar-header__icon'>
        <img src={BChatLogo} alt='' className='sidebar-header-logo' />
      </div>{" "}
      {/* Icon for compact design */}
      <div className='sidebar-header__profile-section'>
        <NotificationBell addtlClass={"notif-side-header"} />
        <img src={userProfile} alt='User Profile' className='sidebar-header__profile-photo' onClick={toggleDropdown} />
        {isDropdownOpen && (
          <div className='sidebar-header__dropdown'>
            <div className='sidebar-header__dropdown-item' onClick={(e) => navigate("/profile")}>
              My Profile
            </div>
            <div className='sidebar-header__dropdown-item' onClick={(e) => navigate("/requests?p=view-requests")}>
              My Request
            </div>
            <div className='sidebar-header__dropdown-item' onClick={(e) => showHideChangePasswordModal()}>
              Change Password
            </div>
            <div className='sidebar-header__dropdown-item' onClick={(e) => handleLogout()}>
              Logout
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default SidebarHeader;
