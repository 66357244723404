import React, { useContext } from "react";
import "./PinModal.css";
import ApiService from "../api/apiService";
import { ChatContext, WebSocketContext } from "../context/contexts";

const PinModal = (props) => {
  const { setShowPinMessageModal, pinMessageData } = props;

  const WS = useContext(WebSocketContext);

  const handlePinMessage = async () => {
    const response = await ApiService.message.pinMessage(pinMessageData.conversation_id, pinMessageData.chat_id);
    if (response && response.success) {
      WS.websocketInstance.pinMessage(pinMessageData.conversation_id, pinMessageData.chat_id, response.message);
      setShowPinMessageModal(false);
    }
  };

  return (
    <div className='pin-modal-overlay'>
      <div className='pin-modal-content'>
        <h2 className='pin-modal-title'>Pin this message?</h2>
        <p className='pin-modal-text'>
          This message will appear at the top of the chat and replace any previously pinned message.
        </p>
        <div className='pin-modal-buttons'>
          <button className='pin-modal-button pin-button' onClick={(e) => handlePinMessage()}>
            Pin
          </button>
          <button className='pin-modal-button cancel-button' onClick={() => setShowPinMessageModal(false)}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default PinModal;
