import React, { useState, useContext, useEffect, useRef } from "react";
import PurchaseRequisition from "./reusable/purchase-requisition";
import ApiService from "../../api/apiService";
import showSwal from "../swal";
import { common } from "../../util/common";
import loader2 from "../../images/loader_2.gif";
import { getCurrentDateToday, convertTo12HourPlusDateFormat } from "../../util/helperFunctions";

function EprForm({ formDetails, setFormSubmitted, setReferenceId, setDateAndTime }) {
  const { id, purchase_requisition_company_detail = [], purchase_requisition_pr_detail = [] } = formDetails;
  const [requisitions, setRequisitions] = useState([{ id: 1 }]);
  const [purchaseType, setPurchaseType] = useState("");
  const [purchaseTypeText, setPurchaseTypeText] = useState("");
  const [company, setCompany] = useState("");
  const [totalCost, setTotalCost] = useState(0);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const recommendedSupplier = useRef(null);
  const urgent = useRef(null);
  const dateNeeded = useRef(null);

  const btnSubmitText = useRef(null);
  const btnLoaderImg = useRef(null);

  const selectCompanyRef = useRef(null);
  const purchaseRequestRef = useRef(null);

  const addRequisition = () => {
    const newRequisition = {
      id: new Date().getTime(), // unique id for each requisition
      description: "",
      remarks: "",
      quantity: 0,
      price: 0,
      cost: 0,
      attachment: [],
      file: [],
    };
    setRequisitions([...requisitions, newRequisition]);
  };

  const removeRequisition = (id) => {
    setRequisitions(requisitions.filter((requisition) => requisition.id !== id));
  };

  const updateRequisition = (updatedRequisition) => {
    setRequisitions((prevRequisitions) =>
      prevRequisitions.map((requisition) =>
        requisition.id === updatedRequisition.id ? updatedRequisition : requisition
      )
    );
  };

  const handlePurchaseRequestOnChange = (e) => {
    setPurchaseType(e.target.value);
    setPurchaseTypeText(e.target.selectedOptions[0].textContent);
  };

  const handleCompanyOnChange = (e) => {
    setCompany(e.target.value);
  };

  const handleTotalCostChange = () => {
    const estimatedCostElements = document.querySelectorAll(".estimated-cost");
    if (estimatedCostElements.length > 0) {
      let totalCost = 0;
      estimatedCostElements.forEach((e) => {
        const target = e;
        totalCost += parseFloat(target.getAttribute("data-value"));
      });
      setTotalCost(totalCost);
    }
  };

  const handleFormValidation = () => {
    let valid = true;
    const selectCompanyValue = selectCompanyRef.current.value;
    const purchaseRequestValue = purchaseRequestRef.current.value;

    const descriptionElemments = document.querySelectorAll(".Description");
    const quantityElements = document.querySelectorAll(".Quantity");

    if (selectCompanyValue === "DEFAULT") {
      selectCompanyRef.current.classList.add("invalid-input-data");
      valid = false;
    }
    if (purchaseRequestValue === "DEFAULT") {
      purchaseRequestRef.current.classList.add("invalid-input-data");
      valid = false;
    }

    if (descriptionElemments.length > 0 && quantityElements.length > 0) {
      descriptionElemments.forEach((el) => {
        const elementValue = el.value;
        if (elementValue.trim() === "") {
          el.classList.add("invalid-input-data");
          valid = false;
        }
      });
      quantityElements.forEach((el) => {
        const elementValue = el.value;
        if (elementValue.trim() === "" || elementValue == 0) {
          el.classList.add("invalid-input-data");
          valid = false;
        }
      });
    }
    if (!valid) showSwal(common.errors.UNFILLED_INPUTS_DATA_ERROR);
    return valid;
  };

  const handleSubmitRequest = async () => {
    const validForm = handleFormValidation();
    if (!validForm) return;
    if (isSubmitting) return;
    setSubmitting(true);
    btnSubmitText.current.style.display = "none";
    btnLoaderImg.current.style.display = "inline-block";

    const data = {
      company_id: company,
      recommended_supplier: recommendedSupplier.current.value,
      purchase_request_id: purchaseType,
      urgent: urgent.current.checked,
      date_needed: dateNeeded.current.value,
      requisitions,
    };
    const formData = new FormData();

    formData.append("company_id", data.company_id);
    formData.append("recommended_supplier", data.recommended_supplier);
    formData.append("purchase_request_id", data.purchase_request_id);
    formData.append("urgent", data.urgent);
    formData.append("date_needed", data.date_needed);

    data.requisitions.forEach((req, index) => {
      formData.append(`requisitions[${index}][id]`, req.id);
      formData.append(`requisitions[${index}][description]`, req.description);
      formData.append(`requisitions[${index}][remarks]`, req.remarks);
      formData.append(`requisitions[${index}][quantity]`, req.quantity);
      formData.append(`requisitions[${index}][price]`, req.price);
      formData.append(`requisitions[${index}][cost]`, req.cost);

      req.attachment.forEach((attachment, attIndex) => {
        formData.append(`requisitions[${index}][attachment][${attIndex}][filePreview]`, attachment.filePreview);
        formData.append(`requisitions[${index}][attachment][${attIndex}][file]`, attachment.file);
        formData.append(`requisitions[${index}][attachment][${attIndex}][type]`, attachment.type);
        formData.append(`requisitions[${index}][attachment][${attIndex}][size]`, attachment.size);
      });

      req.file.forEach((file, fileIndex) => {
        formData.append(`requisitions[${index}][file][${fileIndex}][filePreview]`, file.filePreview);
        formData.append(`requisitions[${index}][file][${fileIndex}][file]`, file.file);
        formData.append(`requisitions[${index}][file][${fileIndex}][type]`, file.type);
        formData.append(`requisitions[${index}][file][${fileIndex}][size]`, file.size);
      });
    });

    const response = await ApiService.forms.submitFormRequest(id, formData);
    const { success, requisition } = response;
    if (!success) return showSwal(common.errors.SUBMIT_EPR_FORM_ERROR);
    setSubmitting(false);
    btnSubmitText.current.style.display = "block";
    btnLoaderImg.current.style.display = "none";
    setReferenceId(requisition.reference_id);
    setDateAndTime(convertTo12HourPlusDateFormat(requisition.createdAt));
    setFormSubmitted(true);
  };

  return (
    <div className='epr-form'>
      <div className='title'>
        <h3>Electronic Purchase Requisition</h3>
      </div>
      <div className='epr-form-fields'>
        <div className='epr-form-column'>
          <div className='epr-form-row'>
            <div>
              <label htmlFor=''>
                Select Company <span className='red-mark-required'>*</span>
              </label>
            </div>
            <select
              name=''
              defaultValue={"DEFAULT"}
              id=''
              onChange={(e) => handleCompanyOnChange(e)}
              required
              ref={selectCompanyRef}
            >
              <option value='DEFAULT' disabled>
                Select a company
              </option>
              {purchase_requisition_company_detail.map((prc, index) => (
                <option value={prc.id} key={index}>
                  {prc.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='epr-form-column'>
          <div className='epr-form-row'>
            <div>
              <label htmlFor=''>Recommended Supplier</label>
            </div>
            <input type='text' ref={recommendedSupplier} />
          </div>
          <div className='epr-form-row'>
            <div>
              <label htmlFor=''>
                Type of Purchase Request <span className='red-mark-required'>*</span>
              </label>
            </div>
            <select
              name=''
              id=''
              defaultValue={"DEFAULT"}
              onChange={(e) => handlePurchaseRequestOnChange(e)}
              ref={purchaseRequestRef}
            >
              <option value='DEFAULT' disabled>
                Select a purchase type
              </option>
              {purchase_requisition_pr_detail.map((pr, index) => (
                <option value={pr.id} key={index}>
                  {pr.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='epr-form-column'>
          <div className='epr-form-row'>
            <div className='epr-form-checkbox-label'>
              <label htmlFor=''>Urgent</label>
            </div>
            <div className='epr-form-checkbox'>
              <input type='checkbox' id='urgent' ref={urgent} />
              <label htmlFor='urgent'>Yes</label>
            </div>
          </div>
        </div>
        <div className='epr-form-column'>
          <div className='epr-form-row'>
            <div className='purchase-requisition-dynamic'>
              <h4>{purchaseTypeText}</h4>
              <div className='purchase-requisition-fields'>
                {requisitions.map((requisition) => (
                  <PurchaseRequisition
                    key={requisition.id}
                    id={requisition.id}
                    onRemove={removeRequisition}
                    onAdd={addRequisition}
                    handleTotalCostChange={handleTotalCostChange}
                    updateRequisition={updateRequisition}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className='epr-form-column'>
          <div className='epr-form-row'>
            <div>
              <label htmlFor=''>Total Cost</label>
            </div>
            <input type='text' value={totalCost} disabled />
          </div>
          <div className='epr-form-row'>
            <div>
              <label htmlFor=''>Date Needed</label>
            </div>
            <input type='date' ref={dateNeeded} defaultValue={getCurrentDateToday()} />
          </div>
        </div>
      </div>
      <div className='submit-action-btn'>
        <button className='search-btn submit-request' onClick={(e) => handleSubmitRequest()}>
          <span className='form-btn-txt' ref={btnSubmitText}>
            Submit Request
          </span>
          <img src={loader2} className='form-loader' ref={btnLoaderImg} style={{ display: "none" }} />
        </button>
      </div>
    </div>
  );
}
export default EprForm;
