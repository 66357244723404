import buildhubImage from "../images/buildhub.png";

const showNotification = (title, body, icon) => {
  if (Notification.permission === "granted") {
    buildNotification(body);
  } else if (Notification.permission !== "denied") {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        buildNotification(body);
      }
    });
  }
};

const buildNotification = (body) => {
  return new Notification("You have unread messages", {
    body: "Check your chat to see the new messages.",
    icon: buildhubImage,
  });
};

export default showNotification;
