import React from "react";
import emptyImage from "../../../images/empty.jpg";

function FileContainer({ attachment, handleAttachmentRemove, removeKey }) {
  return (
    <div className='file-container'>
      <img src={attachment?.filePreview || emptyImage} alt='File preview' />
      <div className='file-description'>
        <span>{attachment?.file?.name}</span>
        <span>{(attachment?.file?.size / 1024).toFixed(2)} KB</span>
      </div>
      <div className='remove-file'>
        <span onClick={(e) => handleAttachmentRemove(attachment?.filePreview, removeKey)}>X</span>
      </div>
    </div>
  );
}

export default FileContainer;
