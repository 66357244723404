import "../styles/profile.css";
import buildhubLogo from "../images/bg.png";
import profileFiller from "../images/empty.jpg";
import ProfileInput from "./reusable/input";
import { useState, useEffect, useContext } from "react";
import { ProfileContext } from "../context/contexts";

function MainProfile() {
  const profileDetail = useContext(ProfileContext);

  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [imageData, setImageData] = useState("");

  useEffect(() => {
    let userProfilePhoto = "";
    if (profileDetail?.userDetailsApi?.profile_photo) {
      userProfilePhoto = `${process.env.REACT_APP_IMAGES_DATA_RESOURCES_URL}/${profileDetail.userDetailsApi.profile_photo}`;
    } else {
      userProfilePhoto = profileFiller;
    }
    setImageData(userProfilePhoto);
  }, [profileDetail]);

  useEffect(() => {
    if (!image) {
      setImagePreview(imageData);
      return;
    }

    const objectUrl = URL.createObjectURL(image);
    setImagePreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [image, imageData]);

  const handleSubmit = () => {
    profileDetail.handleSubmitChanges(imageData);
  };

  return (
    <div className='profile-main' style={{ backgroundImage: `url(${buildhubLogo})` }}>
      <div className='profile-view-container'>
        <div className='profile-image-container'>
          <a href='/'>
            <div className='back-to-main'>
              <i class='fa fa-arrow-left fa-3x' aria-hidden='true'></i>
              <h3>Go Back</h3>
            </div>
          </a>
          <img src={imagePreview} alt='' />
          <input
            type='file'
            onChange={(e) => {
              setImage(e.target.files[0]);
              setImageData(e.target.files[0]);
            }}
          />
        </div>
        <div className='profile-detail-container'>
          <h1>Personal Information</h1>
          <div className='profile-detail'>
            <ProfileInput id='display_name' label='Display Name' />
          </div>
          <div className='profile-detail'>
            <ProfileInput id='id' label='User ID' disabled={true} />
          </div>
          <div className='profile-detail'>
            <ProfileInput id='first_name' label='First Name' disabled={true} />
          </div>
          <div className='profile-detail'>
            <ProfileInput id='last_name' label='Last Name' disabled={true} />
          </div>
          <div className='profile-detail'>
            <ProfileInput id='email_address' label='Email Address' disabled={true} />
          </div>
          <div className='profile-detail'>
            <ProfileInput id='department' label='Department' disabled={true} />
          </div>
          <div className='profile-detail'>
            <ProfileInput id='username' label='Username' disabled={true} />
          </div>
          <div className='profile-detail'>
            <ProfileInput id='admin_access' label='Admin Access' disabled={true} />
          </div>
          <button className='profile-submit-btn' type='button' onClick={handleSubmit}>
            Submit Changes
          </button>
        </div>
      </div>
    </div>
  );
}

export default MainProfile;
