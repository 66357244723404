import React from "react";
function RequestItem({ fieldName, fieldValue }) {
  return (
    <div className='request-item'>
      <span className=''>{fieldName}</span>
      <span>{fieldValue}</span>
    </div>
  );
}

export default RequestItem;
