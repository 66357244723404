function UserDetail({ fieldName, fieldValue }) {
  return (
    <div className='user-detail'>
      <span>{fieldName}</span>
      <span>{fieldValue}</span>
    </div>
  );
}

export default UserDetail;
