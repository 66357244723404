import React, { useState } from "react";
import { PieChart, BarChart, LineChart } from "@mui/x-charts";
import "../../../styles/ReportItem.css";

function ReportItem({}) {
  return (
    <div className='report--item'>
      <h2>User Report</h2>
      <PieChart
        series={[
          {
            data: [
              { id: 0, value: 10, label: "series A" },
              { id: 1, value: 15, label: "series B" },
              { id: 2, value: 20, label: "series C" },
            ],
          },
        ]}
        width={400}
        height={200}
      />
    </div>
  );
}

export default ReportItem;
