import "../../../styles/requests.css";
function RequestItemList({ requestData }) {
  const handleViewBtnClick = (link_id, request_type) => {
    window.location.href = `/requests?p=view-request-detail&t=${request_type}&r=${link_id}&r_url=${window.location.href}`;
  };
  return (
    <div
      className='request-item--list-container'
      onClick={() => handleViewBtnClick(requestData?.id, requestData?.request_type)}
    >
      <div className='request-item--row'>
        <div className='request--item-logo'>
          <i className='fa fa-file-text-o fa-4x' aria-hidden='true'></i>
        </div>
        <div className=''>
          <div className='request-ref-no'>
            <h1>{requestData?.request_id}</h1>
          </div>
          <div className='request--detail'>
            <div className='request--detail-item'>
              <span>Request Type:&nbsp;</span>
              <span>{requestData?.request_type}</span>
            </div>
            <div className='request--detail-item'>
              <span className=''>Date & Time Requested:&nbsp;</span>
              <span className=''>{requestData?.date}</span>
            </div>
            <div className='request--detail-item'>
              <span className=''>Total Cost:&nbsp;</span>
              <span className=''>{requestData?.total_cost}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='request--detail-status'>
        <div
          className={`status ${
            requestData?.status === "Approved" ? "approved" : "pending"
          } request--item-approval-status`}
        >
          <span>{requestData?.status}</span>
        </div>
      </div>
    </div>
  );
}

export default RequestItemList;
