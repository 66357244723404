import React, { useState, useEffect, useContext } from "react";
import emptyImage from "../images/empty.jpg";
import ApiService from "../api/apiService";
import showSwal from "./swal";
import { common } from "../util/common";
import { WebSocketContext, ChatContext } from "../context/contexts";
import { getTimeAgo } from "../util/helperFunctions";

let socket;

function ViewMembersModal({
  conversationData,
  showHideViewMembersModal,
  updateConversationData,
  userDetails,
  conversationUserPermission,
}) {
  const conversation_id = conversationData?.id;
  const conversation_name = conversationData?.name;
  const conversation_members = conversationData?.members;
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  const [runFetchUsernames, setRunFetchUsernames] = useState(true);

  const WS = useContext(WebSocketContext);
  const Chat = useContext(ChatContext);

  useEffect(() => {
    const fetchUserNames = async () => {
      try {
        const response = await ApiService.users.getAllusers();
        const { success, users } = response;
        if (success) {
          const filteredUsers = users.filter(
            (user) => !conversation_members.some((member) => member.user.display_name === user.display_name)
          );
          setAllUsers(filteredUsers);
          setRunFetchUsernames(false);
        } else {
          showSwal(common.errors.CONVERSATION_FETCH_USERNAME_ERROR);
        }
      } catch (error) {
        console.error("Error fetching user names:", error);
      }
    };

    if (runFetchUsernames) {
      fetchUserNames();
    }
  }, [runFetchUsernames, conversation_members]);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchInput(inputValue);
    if (inputValue) {
      const suggestions = allUsers.filter((user) => user.display_name.toLowerCase().includes(inputValue.toLowerCase()));
      setSearchResults(suggestions);
    } else {
      setSearchResults([]);
    }
  };

  const handleMemberViewActions = async (conversation_member_id, action) => {
    try {
      let request;
      switch (action) {
        case "mute":
          request = {
            action: "mute",
          };
          break;
        case "remove":
          request = {
            action: "remove",
          };
          break;
      }

      const response = await ApiService.conversationMembers.updateConversationMember(
        conversation_id,
        conversation_member_id,
        request
      );

      const { success, conversation_action } = response;
      if (success) {
        WS.websocketInstance.sendMessage({ message: conversation_action, type: "user-actions-chat" });
        setRunFetchUsernames(true);
        updateConversationData(conversation_id);
      } else {
        showSwal(common.errors.UPDATE_CONVERSATION_MEMBER_ERROR);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleMemberAdd = async (user_id) => {
    try {
      const response = await ApiService.conversationMembers.addConversationMember(conversation_id, {
        user_id,
      });

      const { success, conversation_action } = response;
      if (success) {
        WS.websocketInstance.sendMessage({ message: conversation_action, type: "user-actions-chat" });
        setSearchResults([]);
        setRunFetchUsernames(true);
        updateConversationData(conversation_id);
      } else {
        showSwal(common.errors.ADD_CONVERSATION_MEMBER_ERROR);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className='modal-container'>
      <div className='modal-dialog'>
        <button className='close-btn' onClick={showHideViewMembersModal}>
          ✕
        </button>
        <h3>View Members in {conversation_name}...</h3>
        <div className='modal-search view-members-modal-search'>
          <input
            type='text'
            className='modal-search-input'
            value={searchInput}
            onChange={(e) => handleInputChange(e)}
            placeholder='Type to search member to add...'
          />
          <ul className='members-suggestion-list'>
            {searchResults.length > 0 &&
              searchResults.map((searchResult, index) => (
                <li key={index} onClick={(e) => handleMemberAdd(searchResult.id)}>
                  {searchResult.display_name}
                </li>
              ))}
          </ul>
          <div className='modal-search-input-icon'>
            <i className='fa fa-search'></i>
          </div>
        </div>
        <ul className='view-members-list'>
          <li>
            <div className='result-container view-member-container-header'>
              <div className='view-member-container-header-title'>
                <h4>Name</h4>
              </div>
              <div className='view-member-container-header-title'>
                <h4>Email</h4>
              </div>
              <div className='view-member-container-header-title'>
                <h4>Action</h4>
              </div>
            </div>
          </li>
          {conversation_members.length > 0 ? (
            conversation_members.map((result, index) => {
              const profileImage =
                result?.user?.profile_photo === "" || result?.user?.profile_photo === null
                  ? emptyImage
                  : `${process.env.REACT_APP_IMAGES_DATA_RESOURCES_URL}/${result?.user?.profile_photo}`;
              const conversation_member_id = result?.id;
              const isMuted = result?.is_muted;
              const isSelf = result?.user?.id === userDetails.id;
              const isSelfAdmin = conversationUserPermission.is_admin;

              const onlineUserList = Chat.onlineUsers;
              const onlineData = onlineUserList.find((onlineUser) => onlineUser.user_id === result?.user?.id);

              const userOnline = onlineData ? onlineData?.is_online : false;
              const lastLogin = userOnline ? "" : onlineData?.last_online ? getTimeAgo(onlineData?.last_online) : null;
              return (
                <li key={index}>
                  <div className='result-container view-member-result-container'>
                    <div className='view-members-column view-members-column-name view-members-detail-container'>
                      <img src={profileImage} className='result-chat-image' alt='' />
                      <div className={`online-indicator-members ${userOnline ? "online-user" : "offline-user"}`}>
                        <span className='tooltip'>{`${
                          userOnline
                            ? "Online"
                            : `${lastLogin !== null && lastLogin !== undefined ? lastLogin : "Offline"}`
                        }`}</span>
                      </div>
                      <span className='result-chat-username view-member-display-name'>
                        {result?.user?.display_name}&nbsp;
                      </span>
                    </div>
                    <div className='view-members-column view-members-detail-container'>
                      <span className='result-chat-username view-member-display-name'>
                        {result?.user?.email_address}&nbsp;
                      </span>
                    </div>

                    <div className='view-members-column view-members-btn-container'>
                      {!isSelf && isSelfAdmin ? (
                        <>
                          <button
                            className='view-members-remove-btn remove-btn text-red'
                            onClick={(e) => handleMemberViewActions(conversation_member_id, "remove")}
                          >
                            Remove
                          </button>
                          <button
                            className='view-members-remove-btn remove-btn'
                            onClick={(e) => handleMemberViewActions(conversation_member_id, "mute")}
                          >
                            {!isMuted ? "Mute" : "Unmute"}
                          </button>
                        </>
                      ) : (
                        <>
                          <small className='text-red'>DISABLED</small>
                        </>
                      )}
                    </div>
                  </div>
                </li>
              );
            })
          ) : (
            <li>
              <span className='no-chats-found'>NO MEMBERS FOUND</span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

export default ViewMembersModal;
