import React, { useState } from "react";

function FormSelectModal({ modalTitle, handleShowingModal, formOptions = [] }) {
  const [selectedForm, setSelectedForm] = useState("");

  const handleProceedClick = () => {
    if (selectedForm) {
      window.location.href = `/requests?p=new-request&f=${selectedForm}`;
    } else {
      alert("Please select a form before proceeding.");
    }
  };

  const handleSelectChange = (event) => {
    setSelectedForm(event.target.value);
  };

  return (
    <div className='modal-container'>
      <div className='modal-dialog'>
        <button className='close-btn' onClick={handleShowingModal}>
          ✕
        </button>
        <h3>{modalTitle}</h3>
        <div className='modal-form-select'>
          <select value={selectedForm} onChange={handleSelectChange}>
            <option value='' disabled>
              Select a form
            </option>
            {formOptions.map((form, index) =>
              form.add_page ? (
                <option value={form.form_id} key={index}>
                  {form.form_details.name}
                </option>
              ) : (
                ""
              )
            )}
          </select>
          <button className='search-btn' onClick={handleProceedClick}>
            Proceed
          </button>
        </div>
      </div>
    </div>
  );
}

export default FormSelectModal;
