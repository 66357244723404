function PreviewImageModal(props) {
  const { isPreviewOpen, previewImage, closeModal } = props;
  return (
    <div className={`image-preview-modal ${isPreviewOpen ? "open" : "close"}`} onClick={closeModal}>
      <div className='image-preview-modal-content'>
        <img src={previewImage} alt='Preview' className='image-preview' />
      </div>
    </div>
  );
}

export default PreviewImageModal;
