import React, { useState, useEffect } from "react";
import emptyImage from "../images/empty.jpg";
import ApiService from "../api/apiService";
import showSwal from "./swal";
import { trimWord, dateToWords } from "../util/helperFunctions";
import { common } from "../util/common";

function ViewConversationFilesModal({ conversationData, showHideViewConversationFilesModal, userDetails }) {
  const conversation_id = conversationData?.id;
  const conversation_name = conversationData?.name;
  const conversation_members = conversationData?.members;

  const [filesData, setFilesData] = useState([]);

  const fetchFilesData = async () => {
    try {
      if (conversation_id) {
        const response = await ApiService.files.getAllConversationFiles(conversation_id);
        const { success, files } = response;

        if (!success) return showSwal(common.errors.VIEW_CONVERSATION_FILES_ERROR);

        setFilesData(files);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchFilesData();
    return () => {};
  }, []);

  const sortedFiles = filesData.sort(
    (a, b) => new Date(b.message_attachment[0]?.createdAt) - new Date(a.message_attachment[0]?.createdAt)
  );
  return (
    <div className='modal-container'>
      <div className='modal-dialog'>
        <button className='close-btn' onClick={showHideViewConversationFilesModal}>
          ✕
        </button>
        <h3>View Files in {conversation_name}...</h3>

        <ul className='view-members-list'>
          <li>
            <div className='result-container view-member-container-header'>
              <div className='view-member-container-header-title'>
                <h4>Display Name</h4>
              </div>
              <div className='view-member-container-header-title'>
                <h4>File Name</h4>
              </div>
              <div className='view-member-container-header-title'>
                <h4>Preview</h4>
              </div>
            </div>
          </li>
          {sortedFiles.length > 0 ? (
            sortedFiles.map((result, index) => {
              const profileImage =
                result?.user?.profile_photo === "" || result?.user?.profile_photo === null
                  ? emptyImage
                  : `${process.env.REACT_APP_IMAGES_DATA_RESOURCES_URL}/${result?.user?.profile_photo}`;
              const fileName = result?.message_attachment[0]?.attachment || "";
              const fileDate = result?.message_attachment[0]?.createdAt || "";
              return (
                <li key={index}>
                  <div className='result-container view-member-result-container'>
                    <div className='view-members-column view-members-column-name view-members-detail-container'>
                      <img src={profileImage} className='result-chat-image' alt='' />
                      <span className='result-chat-username view-member-display-name'>
                        {result?.user?.display_name}&nbsp;
                      </span>
                    </div>
                    <div className='view-members-column view-members-detail-container'>
                      <span className='result-chat-username view-member-display-name file-name-span'>
                        {`${trimWord(fileName, 15)} | ${dateToWords(fileDate)}`}
                      </span>
                    </div>

                    <div className='view-members-column view-members-btn-container'>
                      <small className='text-blue-underline file-name-span'>
                        <a href={`${process.env.REACT_APP_CHAT_RESOURCES_URL}/${fileName}`} target='_blank'>
                          {`VIEW`}
                        </a>
                      </small>
                    </div>
                  </div>
                </li>
              );
            })
          ) : (
            <li>
              <span className='no-chats-found'>NO FILES FOUND</span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

export default ViewConversationFilesModal;
