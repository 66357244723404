import React from "react";
import emptyImage from "../../../images/empty.jpg";
import RequestItem from "./request-item";
import { formatToPeso } from "../../../util/helperFunctions";

function RequestDetailItem({ prDetail }) {
  const renderDynamicAttachmentsFiles = (file_type, attachment, index) => {
    const resourcesUrl = process.env.REACT_APP_ATTACHMENT_DATA_RESOURCES_URL;
    if (file_type.includes("image")) {
      return (
        <div className='attachment-item' key={index}>
          <img src={`${resourcesUrl}${attachment}`} alt='' />
        </div>
      );
    } else {
      return (
        <div className='attachment-item' key={index}>
          <a href={`${resourcesUrl}${attachment}`} target='_blank' rel='noopener noreferrer'>
            <i className='fa fa-file-o fa-4x' aria-hidden='true'></i>
          </a>
        </div>
      );
    }
  };
  return (
    <div className='request-detail-row request-detail--item'>
      <RequestItem fieldName={"Description"} fieldValue={prDetail?.description} />
      <RequestItem fieldName={"Remarks"} fieldValue={prDetail?.remarks} />
      <RequestItem fieldName={"Quantity"} fieldValue={prDetail?.quantity} />
      <RequestItem fieldName={"Estimated Price"} fieldValue={formatToPeso(prDetail?.estimated_price)} />
      <RequestItem fieldName={"Estimated Cost"} fieldValue={formatToPeso(prDetail?.estimated_cost)} />

      <div className='request-item'>
        <span className=''>Attachments</span>
        <div className='request-item-attachment-container'>
          {prDetail?.purchases_attachment_detail?.length > 0 ? (
            prDetail?.purchases_attachment_detail?.map((prAttachment, index) =>
              renderDynamicAttachmentsFiles(prAttachment.file_type, prAttachment.attachment, index)
            )
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <div className='request-item'>
        <span className=''>Files</span>
        <div className='request-item-attachment-container'>
          {prDetail?.purchases_files_detail?.length > 0 ? (
            prDetail?.purchases_files_detail?.map((prAttachment, index) =>
              renderDynamicAttachmentsFiles(prAttachment.file_type, prAttachment.attachment, index)
            )
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
}

export default RequestDetailItem;
