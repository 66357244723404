import React, { useState, useEffect, useContext } from "react";
import BuildhubLogoSmall from "../../images/buildhub-small.png";
import buildhubBg from "../../images/bg.png";

function RequestSideBar({ pagePermissions }) {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(true);

  const handleSideBarCollapsedClick = () => {
    setSidebarCollapsed((prevState) => !sidebarCollapsed);
  };
  return (
    <div className={`sidebar ${sidebarCollapsed ? "close" : ""}`} style={{ backgroundImage: `url(${buildhubBg})` }}>
      <div className='logo-details'>
        <i className='fa fa-bars' aria-hidden='true' onClick={(e) => handleSideBarCollapsedClick()}></i>
      </div>
      <ul className='nav-links'>
        {/* <li>
          <a href='/requests?p=dashboard'>
            <i className='fa fa-home' aria-hidden='true'></i>

            <span className='link_name'>Dashboard</span>
          </a>
        </li> */}
        {pagePermissions?.approval_page && (
          <>
            <li>
              <a href='/requests?p=view-approval-list'>
                <i className='fa fa-thumbs-o-up' aria-hidden='true'></i>

                <span className='link_name'>Approvals</span>
              </a>
            </li>
            <li>
              <a href='/requests?p=view-approval-history'>
                <i className='fa fa-history' aria-hidden='true'></i>

                <span className='link_name'>Requests History</span>
              </a>
            </li>
          </>
        )}
        <li>
          <a href='/requests?p=view-requests'>
            <i className='fa fa-file-text-o' aria-hidden='true'></i>

            <span className='link_name'>My Requests</span>
          </a>
        </li>
        <li>
          <a href='/requests?p=view-forms'>
            <i className='fa fa-list' aria-hidden='true'></i>

            <span className='link_name'>My Forms</span>
          </a>
        </li>
      </ul>
    </div>
  );
}

export default RequestSideBar;
