import "../../../styles/requests.css";
import { convertTo12HourPlusDateFormat, formatToPeso } from "../../../util/helperFunctions";
function RequestHistoryItem({ data }) {
  const handleViewBtnClick = (link_id, request_type) => {
    window.location.href = `/requests?p=view-request-detail&t=${request_type}&r=${link_id}&r_url=${window.location.href}`;
  };
  return (
    <div className='request-item--list-container' onClick={() => handleViewBtnClick(data?.id, data?.request_type)}>
      <div className='request-item--row'>
        <div className='request--item-logo'>
          <i className='fa fa-file-text-o fa-4x' aria-hidden='true'></i>
        </div>
        <div className=''>
          <div className='request-ref-no'>
            <h1>{data?.reference_id}</h1>
          </div>
          <div className='request--detail'>
            <div className='request--detail-item'>
              <span>Employee:&nbsp;</span>
              <span>{data?.user_detail?.display_name}</span>
            </div>
            <div className='request--detail-item'>
              <span>Request Type:&nbsp;</span>
              <span>{data?.request_type}</span>
            </div>
            <div className='request--detail-item'>
              <span className=''>Date & Time Requested:&nbsp;</span>
              <span className=''>{convertTo12HourPlusDateFormat(data?.createdAt)}</span>
            </div>
            <div className='request--detail-item'>
              <span className=''>Total Cost:&nbsp;</span>
              <span className=''>{formatToPeso(data?.total_cost)}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='request--detail-status'>
        <div
          className={`status ${data?.overall_approval_status ? "approved" : "pending"} request--item-approval-status`}
        >
          <span>{data?.overall_approval_status ? "Approved" : "Pending"}</span>
        </div>
      </div>
    </div>
  );
}

export default RequestHistoryItem;
