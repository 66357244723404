import React, { useState, useEffect } from "react";
import RequestSideBar from "./sidebar";
import RequestMain from "./main";
import MyForms from "./my-forms";
import buildhubBg from "../../images/bg.png";
import { useLocation } from "react-router-dom";
import FormSelectModal from "./reusable/modal";
import RequestNew from "./request-new";
import showSwal from "../swal";
import ApiService from "../../api/apiService";
import { common } from "../../util/common";
import RequestDetail from "./view-request-detail";
import ApproversModal from "./reusable/approvers-modal";
import ApprovalRequests from "./approval-requests";
import RequestsHistory from "./requests-history";
import Dashboard from "./dashboard";

function RequestIndex({ userDetails }) {
  const [viewingPage, setViewingPage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showApproversModal, setShowApproversModal] = useState(false);
  const [availableForms, setAvailableForms] = useState([]);
  const [approversData, setApproversData] = useState(null);
  const [pagePermissions, setPagePermissions] = useState(null);

  const location = useLocation();

  useEffect(() => {
    const getForms = async () => {
      const myForms = await ApiService.forms.getMyForms();
      const { success, forms } = myForms;
      if (!success) return showSwal(common.errors.FETCH_MY_FORMS_ERROR);
      setAvailableForms(forms);
    };

    const getPagePermissions = async () => {
      const pagePermissions = await ApiService.forms.getPagePermissions();
      const { success, permissions } = pagePermissions;

      if (!success) return showSwal(common.errors.PAGE_PERMISSIONS_ERROR);
      setPagePermissions(permissions);
    };

    getForms();
    getPagePermissions();
  }, []);

  const handleShowingModal = () => {
    setShowModal((prevState) => !showModal);
  };

  const handleShowApproversModal = () => {
    setShowApproversModal(() => !showApproversModal);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const page = searchParams.get("p");
    if (page !== "" && page !== null) {
      setViewingPage(page);
    }
  }, [location.search]);

  return (
    <div className='requests-index'>
      {showModal && (
        <FormSelectModal
          modalTitle={"Select a form"}
          handleShowingModal={handleShowingModal}
          formOptions={availableForms}
        />
      )}
      {showApproversModal && (
        <ApproversModal handleShowApproversModal={handleShowApproversModal} approversData={approversData} />
      )}
      <div className='requests-sidebar'>
        <RequestSideBar pagePermissions={pagePermissions} />
      </div>
      <div className='requests-main' style={{ backgroundImage: `url(${buildhubBg})` }}>
        {viewingPage === "view-forms" ? (
          <MyForms />
        ) : viewingPage === "dashboard" ? (
          <Dashboard />
        ) : viewingPage === "view-requests" ? (
          <RequestMain handleShowingModal={handleShowingModal} />
        ) : viewingPage === "new-request" ? (
          <RequestNew />
        ) : viewingPage === "view-request-detail" ? (
          <RequestDetail
            handleShowApproversModal={handleShowApproversModal}
            setApproversData={setApproversData}
            userDetails={userDetails}
          />
        ) : viewingPage === "view-approval-list" && pagePermissions?.approval_page ? (
          <ApprovalRequests />
        ) : viewingPage === "view-approval-history" && pagePermissions?.approval_page ? (
          <RequestsHistory />
        ) : (
          <div>No content available for this page.</div>
        )}
      </div>
    </div>
  );
}

export default RequestIndex;
