import emptyImage from "../../../images/empty.jpg";
import { convertTo12HourPlusDateFormat } from "../../../util/helperFunctions";

function ApproversModal({ handleShowApproversModal, approversData }) {
  if (!approversData) return;
  const { first_level_approval, second_level_approval } = approversData;

  return (
    <div className='modal-container'>
      <div className='modal-dialog'>
        <button className='close-btn' onClick={handleShowApproversModal}>
          ✕
        </button>
        <div className='modal---header'>
          <h3>Approvers</h3>
        </div>
        <div className='modal---body'>
          <div className='initial-approvers-list'>
            <h3>1st Level</h3>
            <div className='approvers-list--header'>
              {first_level_approval.map((approver, index) => (
                <div className='approver-item' key={index}>
                  <div className='approver-user-detail'>
                    <img
                      src={
                        approver?.profile_photo !== "" && approver?.profile_photo
                          ? `${process.env.REACT_APP_IMAGES_DATA_RESOURCES_URL}${approver?.profile_photo}`
                          : emptyImage
                      }
                      alt=''
                    />
                    <span>{approver?.display_name}</span>
                  </div>
                  <div className='status---container'>
                    <div>
                      <span
                        className={`status ${
                          approver?.approved ? "approved" : approver?.has_voted ? "rejected" : "pending"
                        }`}
                      >
                        {approver?.approved ? "Approved" : approver?.has_voted ? "Rejected" : "Pending"}
                      </span>
                    </div>
                    <span>{approver?.date ? convertTo12HourPlusDateFormat(approver.date) : ""}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className='second-approvers-list'>
            <h3>2nd Level</h3>
            <div className='approvers-list--header'>
              {second_level_approval.map((approver, index) => (
                <div className='approver-item' key={index}>
                  <div className='approver-user-detail'>
                    <img
                      src={
                        approver?.profile_photo !== "" && approver?.profile_photo
                          ? `${process.env.REACT_APP_IMAGES_DATA_RESOURCES_URL}${approver?.profile_photo}`
                          : emptyImage
                      }
                      alt=''
                    />
                    <span>{approver?.display_name}</span>
                  </div>
                  <div className='status---container'>
                    <div>
                      <span
                        className={`status ${
                          approver?.approved ? "approved" : approver?.has_voted ? "rejected" : "pending"
                        }`}
                      >
                        {approver?.approved ? "Approved" : approver?.has_voted ? "Rejected" : "Pending"}
                      </span>
                    </div>
                    <span>{approver?.date ? convertTo12HourPlusDateFormat(approver?.date) : ""}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='modal---footer'>
          {/* <button className='action-btn approve-btn'>Approve Request</button>
          <button className='action-btn deny-btn'>Deny Request</button> */}
        </div>
      </div>
    </div>
  );
}

export default ApproversModal;
