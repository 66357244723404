import React, { useState, useEffect, useRef, useContext } from "react";
import "../styles/style.css";
import { convertTo12HourFormat, trimWord } from "../util/helperFunctions";
import emptyImage from "../images/empty.jpg";
import env from "react-dotenv";
import MessageComponent from "./message";
import { ChatContext } from "../context/contexts";
import likeReaction from "../images/reactions/like-reaction.png";
import heartReaction from "../images/reactions/heart-reaction.png";
import hahaReaction from "../images/reactions/haha-reaction.png";
import emojiReaction from "../images/emotion.png";
import ReactionsIndicator from "./reusable/reactions-indicator";
import { groupReactions, getTimeAgo } from "../util/helperFunctions";
import ApiService from "../api/apiService";

function MessageReceiver(props) {
  const Chat = useContext(ChatContext);

  const {
    messageDetail,
    userDetails,
    handleImageClick,
    toggleShowChatReply,
    setChatReplyData,
    toggleShowReactionOptions,
    onClickPin,
  } = props;
  const {
    id: chat_id,
    conversation_id,
    content,
    createdAt,
    is_deleted,
    message_attachment,
    reply_to_message_detail,
    user,
    thread_replies,
    is_pinned,
    message_reactions,
    message_reads,
  } = messageDetail;

  const [isShowChatOptions, setShowChatOptions] = useState(false);

  const [____isDeleted, ____setIsDeleted] = useState(false);

  const dropdownRef = useRef(null);

  const groupedReaction = groupReactions(message_reactions);

  const [onlineUserData, setOnlineUserData] = useState(null);

  const messageContainerRef = useRef(null);

  const messagesReadsUserNames = message_reads
    ?.filter((messageRead) => messageRead?.user?.display_name)
    .filter((messageRead, index, self) => {
      const userId = messageRead.user.id;
      return index === self.findIndex((msg) => msg.user.id === userId);
    })
    .map((messageRead) => (messageRead.user.id === userDetails.id ? "You" : messageRead.user.display_name));

  useEffect(() => {
    const onlineUsers = Chat.onlineUsers;

    const onlineData = onlineUsers.find((onlineUser) => onlineUser.user_id === user.id);
    setOnlineUserData(onlineData);
  }, [Chat.onlineUsers]);

  const handleOnClickReply = () => {
    const replyData = {
      chat_id,
      conversation_id,
      display_name: user.display_name,
      message: content,
    };

    setShowChatOptions(false);
    setChatReplyData(replyData);
  };

  const handleOnClickPin = (e) => {
    const target = e.target;
    const parentElement = target.closest(".message__item-container");

    if (parentElement.style.zIndex === "9999999") {
      parentElement.style.zIndex = "1";
    } else {
      parentElement.style.zIndex = "9999999";
    }

    onClickPin(conversation_id, chat_id);
  };

  const handleOnThreadreply = (e) => {
    const target = e.target;
    const parentElement = target.closest(".message__item-container");

    if (parentElement.style.zIndex === "9999999") {
      parentElement.style.zIndex = "1";
    } else {
      parentElement.style.zIndex = "9999999";
    }

    Chat.handleThreadViewMsgId(chat_id);
  };

  const toggleShowChatOptions = (e) => {
    const target = e.target;
    const parentElement = target.closest(".message__item-container");
    if (parentElement.style.zIndex === "9999999") {
      parentElement.style.zIndex = "1";
      parentElement.style.position = "initial";
    } else {
      parentElement.style.zIndex = "9999999";
      parentElement.style.position = "relative";
    }

    setShowChatOptions((prevState) => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        messageContainerRef.current.style.zIndex = 1;
        setShowChatOptions(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const hasAttachment = message_attachment.length > 0;

  const FINAL_RESOURCES_URL = process.env.REACT_APP_CHAT_RESOURCES_URL;

  const userDisplayName = userDetails.display_name;
  const isReplyingToOwn = reply_to_message_detail?.user?.display_name === userDisplayName;
  const isReplyingToThemselves = reply_to_message_detail?.user?.display_name === user?.display_name;

  const profileImage =
    user.profile_photo === "" || user.profile_photo === null
      ? emptyImage
      : `${process.env.REACT_APP_IMAGES_DATA_RESOURCES_URL}/${user.profile_photo}`;

  const userOnline = onlineUserData ? onlineUserData?.is_online : false;
  const lastLogin = userOnline ? "" : onlineUserData?.last_online ? getTimeAgo(onlineUserData?.last_online) : null;
  return (
    <div>
      <div className='message__item-container message__item-container--receiver' ref={messageContainerRef}>
        <div className='message__item-container-2'>
          <div className='user--container'>
            <img className='message__item-img' src={profileImage} alt='Seller image' />
            <div className={`online-indicator ${userOnline ? "online-user" : "offline-user"}`}>
              <span className='tooltip'>{`${
                userOnline ? "Online" : `${lastLogin !== null && lastLogin !== undefined ? lastLogin : "Offline"}`
              }`}</span>
            </div>
          </div>
          <div className='message__item-message'>
            {reply_to_message_detail && (
              <div className='message__item-container-reply--receiver'>
                <small className='message__item-container-reply--detail'>
                  {user?.display_name} replied to{" "}
                  {isReplyingToOwn
                    ? "you"
                    : isReplyingToThemselves
                    ? "themselves"
                    : reply_to_message_detail.user.display_name}
                </small>
                <span className='message__item-container-reply-detail--receiver'>
                  {reply_to_message_detail.is_deleted ? "DELETED CHAT" : trimWord(reply_to_message_detail.content, 25)}
                </span>
              </div>
            )}
            <span className='message__item-details'>
              {!reply_to_message_detail && <span className='message__item-name'>{user?.display_name}</span>}
              {/* <span className='message__item-time'>{convertTo12HourFormat(createdAt)}</span> */}
            </span>

            <div>
              <div className='message__item-text-container-receiver' id={chat_id}>
                {is_pinned && (
                  <div className='message-pin-container-receiver'>
                    <div>📌</div>
                  </div>
                )}
                <span
                  className={`message__item-text message__item-text--receiver ${
                    is_deleted ? "message__item-deleted" : ""
                  }`}
                  onMouseEnter={(e) => {
                    if (e.target.nextElementSibling) {
                      e.target.nextElementSibling.style.display = "flex";
                    }
                  }}
                  onMouseLeave={(e) => {
                    if (e.target.nextElementSibling) {
                      e.target.nextElementSibling.style.display = "none";
                    }
                  }}
                >
                  <MessageComponent message={content} is_deleted={is_deleted} />
                  {!is_deleted &&
                    hasAttachment &&
                    message_attachment.map((_messageAttachment, index) => {
                      const attachmentType = hasAttachment ? _messageAttachment.file_type : null;
                      const isImageAttachment = attachmentType && attachmentType.includes("image");
                      const isFileAttachment = attachmentType && attachmentType.includes("pdf");
                      const isVideoAttachment = attachmentType && attachmentType.includes("video");

                      const attachmentLink = `${FINAL_RESOURCES_URL}${_messageAttachment.attachment}`;

                      if (isImageAttachment) {
                        return (
                          <img
                            key={index}
                            src={attachmentLink}
                            alt='Attachment'
                            className='message__item-attachment'
                            onClick={() => handleImageClick([{ src: attachmentLink, type: "image" }])}
                            style={{ cursor: "pointer" }}
                          />
                        );
                      } else if (isVideoAttachment) {
                        return (
                          <video
                            key={index}
                            controls
                            src={attachmentLink}
                            className='message__item-attachment'
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleImageClick([
                                { type: "video", sources: [{ src: attachmentLink, type: "video/mp4" }] },
                              ])
                            }
                          >
                            Your browser does not support the video tag.
                          </video>
                        );
                      } else if (isFileAttachment) {
                        return (
                          <a
                            key={index}
                            href={attachmentLink}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='message__item-attachment-file'
                          >
                            Click to view the file
                          </a>
                        );
                      } else {
                        return (
                          <a
                            key={index}
                            href={attachmentLink}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='message__item-attachment-file'
                          >
                            Click to view the file
                          </a>
                        );
                      }
                    })}
                </span>
                <span className={`message__item-text message__item-text--receiver message__item-time`}>
                  <p>{convertTo12HourFormat(createdAt)}</p>
                </span>
                {!is_deleted && (
                  <div className='message__item-dropdown reaction-option-emoji-btn' ref={dropdownRef}>
                    <button type='button ' onClick={toggleShowChatOptions}>
                      <i className='fa fa-ellipsis-h message__item-ellipsis'></i>
                    </button>
                    <div
                      className={`message__item-dropdown-item message-options-receiver ${
                        isShowChatOptions ? "show" : ""
                      }`}
                    >
                      <button type='button' onClick={(e) => handleOnClickPin(e)}>
                        Pin
                      </button>
                      <button type='button' onClick={handleOnClickReply}>
                        Reply
                      </button>
                      <button type='button' onClick={(e) => handleOnThreadreply(e)}>
                        Reply in thread
                      </button>
                    </div>
                  </div>
                )}
                <div className='message__item-dropdown'>
                  {!is_deleted && (
                    <button
                      type='button'
                      onClick={(e) => toggleShowReactionOptions(chat_id, groupedReaction)}
                      className='reaction-option-emoji-btn'
                    >
                      <img src={emojiReaction} className='reaction-option-emoji' />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='move-right'>
          <div className='view-chat-reactions view-chat-reactions-receiver'>
            {Object.keys(groupedReaction).map((reaction, index) => (
              <ReactionsIndicator reaction={`${reaction}`} reaction_details={groupedReaction[reaction]} key={index} />
            ))}
          </div>
          {thread_replies.length > 0 && (
            <div className='view-thread-replies-receiver'>
              <small className='thread-indicator' onClick={(e) => Chat.handleThreadViewMsgId(chat_id)}>
                {thread_replies.length} Thread Replies
              </small>
            </div>
          )}
          {messagesReadsUserNames && messagesReadsUserNames.length > 0 && (
            <div className='seen-members-container'>
              <small>Seen by {messagesReadsUserNames.join(", ")}</small>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default MessageReceiver;
