import React, { useState, useEffect, useContext } from "react";
import ApiService from "../../api/apiService.js";
import showSwal from "../swal.jsx";
import { common } from "../../util/common.js";
import ReportItem from "./reusable/report.item.jsx";

function Dashboard() {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const getForms = async () => {
      const myForms = await ApiService.forms.getApprovalRequestsList();
      const { success, requests } = myForms;
      if (!success) return showSwal(common.errors.GETTING_APPROVAL_LIST_ERROR);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    };

    getForms();
  }, []);

  return (
    <div>
      <div className='request-main-title'>
        <h3>Dashboard</h3>
      </div>
      {isLoading ? (
        <div className='loader--container'>
          <div className='loader--'></div>
        </div>
      ) : (
        <div className='report-dashboard-container '>
          <ReportItem />
          <ReportItem />
          <ReportItem />
        </div>
      )}
    </div>
  );
}

export default Dashboard;
