import io from "socket.io-client";

class Websocket {
  static instance;
  socket;
  userId;
  displayName;
  roomId;

  constructor() {
    if (!Websocket.instance) {
      Websocket.instance = this;
    }
    return Websocket.instance;
  }

  initConnection(userId, displayName) {
    if (!this.socket) {
      this.socket = io(process.env.REACT_APP_API_URL);
      this.userId = userId;
      this.displayName = displayName;
      this.setupVisibilityChangeListener();
      // this.initSidebarRooms();
    }
  }

  indicateOnline(userId, displayName, callbackUserList) {
    if (this.socket) {
      // Sends user online websocket
      this.socket.emit("user_online", { userId, name: displayName }, (callback) => {
        if (callback === "OK") {
          setTimeout(() => {
            setInterval(() => {
              // PING
              this.socket.emit("user_online_ping_pong", { userId }, (callback) => {});
            }, 60000); // 1 Minute to send ping to the server that the user is still online
          }, 3000);
        }
      });
      // Receive list of users that is online
      this.socket.on("update_user_list", (userList) => {
        callbackUserList(userList);
      });

      // Ping-pong mechanism
      // PONG
      this.socket.on("ping_pong_response", ({ userId, message }) => {
        console.log(`Response from server! User ID: ${userId}`);
      });
      // Ping-pong mechanism

      this.socket.on("disconnect", () => {
        console.log(`${displayName} has disconnected`);
      });
    }
  }

  logout() {
    const socket = this.socket;
    if (socket && socket.connected) {
      socket.disconnect();
    }
  }

  setupListeners(onMessageReceived) {
    this.socket.on("message", (data) => {
      if (
        (data && (data.type === "user-chat" || data.type === "user-actions-chat")) ||
        data.type === "user-thread-chat" ||
        data.type === "user-reactions-chat"
      ) {
        onMessageReceived(data.message_detail);
      }
    });
    this.socket.on("messageReaction", (data) => {
      if (data && data.type === "user-reactions-chat") {
        onMessageReceived(data.message_detail);
      }
    });
  }

  seenMessage(user_id, display_name, conversation_id, message_id) {
    this.socket.emit("seen_message", {
      userId: user_id,
      display_name: display_name,
      conversationId: conversation_id,
      messageId: message_id,
    });
  }

  receiveSeenMessage(onSeenMessage) {
    this.socket.on("receive_seen_message", (data) => {
      console.log(data);
      onSeenMessage(data);
    });
  }

  pinMessage(conversation_id, user_id, pin_data) {
    this.socket.emit("pin_message", {
      conversation_id,
      user_id,
      pin_data,
    });
  }

  receivePinMessage(onPinMessage) {
    this.socket.on("receive_pin_message", (data) => {
      console.log(data);
      onPinMessage(data);
    });
  }

  setupDisconnectListener() {
    this.socket.on("disconnect", () => {
      console.log("Socket disconnected");
      this.roomId = null;
    });
  }

  getConnection() {
    return this.socket;
  }

  isConnectedRoom() {
    return this.socket && this.roomId ? { connected: true, socket: this.socket } : { connected: false };
  }

  connectRoom(roomId) {
    this.disconnectRoom();
    if (this.socket) {
      this.roomId = roomId;
      this.socket.emit("join", { userId: this.userId, name: this.displayName, room: roomId }, (error) => {
        if (error) {
          // alert(error);
        }
      });
    }
  }

  currentlyTyping(userId, display_name, profile_photo, conversation_id) {
    if (this.socket) {
      this.socket.emit("currently_typing", {
        userId: userId,
        display_name: display_name,
        profile_photo: profile_photo,
        room: conversation_id,
      });
    }
  }

  currentlyTypingListener(onTypingReceived) {
    this.socket.on("currently_typing_feedback", (typing) => {
      onTypingReceived(typing);
    });
  }

  sendMessage(messageDetail) {
    if (this.socket) {
      this.socket.emit("sendMessage", {
        message: messageDetail,
        room: messageDetail.message.conversation_id,
        type: messageDetail.type,
      });
      this.socket.emit("sidebar_sendMessage", {
        message: messageDetail,
        room: messageDetail.message.conversation_id,
        type: "user-sidebar-chat",
      });
    }
  }

  sendMsgReaction(reactionDetail, conversation_id) {
    if (this.socket) {
      this.socket.emit("sendMsgReaction", {
        message: reactionDetail,
        room: conversation_id,
        type: reactionDetail.type,
      });
    }
  }

  disconnectRoom() {
    if (this.socket && this.roomId) {
      this.socket.emit("leave", { userId: this.userId, roomId: this.roomId }, (error) => {
        if (error) {
          alert(error);
        }
      });
      this.roomId = null;
    }
  }

  checkConnection() {
    if (!this.socket || !this.socket.connected) {
      window.location.reload();
    }
  }
  startConnectionCheck() {
    setInterval(() => this.checkConnection(), 5000);
  }

  setupVisibilityChangeListener() {
    document.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        if (!this.socket.connected) {
          this.socket.connect();
        }
      }
    });
  }
  // SIDE BAR WS
  // initSidebarRooms() {
  //   if (this.socket) {
  //     this.socket.emit("sidebar_join", { userId: this.userId, name: this.displayName }, (error) => {
  //       if (error) {
  //         alert(error);
  //       }
  //     });
  //   }
  // }

  // setupSidebarListeners(onMessageReceived) {
  //   this.socket.on("sidebar_message", (data) => {
  //     if (data && data.type === "user-sidebar-chat") {
  //       onMessageReceived(data);
  //     }
  //   });
  // }
}

export default Websocket;
