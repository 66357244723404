import emptyImage from "../images/empty.jpg";
import { convertTo12HourPlusDateFormat, highlightText, trimWord } from "../util/helperFunctions";
import ApiService from "../api/apiService";
import showSwal from "./swal";
import { common } from "../util/common";

function SearchResults({ searchResult, searchQuery }) {
  const handleResultClick = async () => {
    const msgIndex = await ApiService.message.getMessagePosition(searchResult.conversation_id, searchResult.id);
    const { success, response } = msgIndex;
    if (!success) return showSwal(common.errors.GLOBAL_SEARCH_RESULT_CLICK_ERROR);

    const { thread, index, query } = response;
    const { thread_reply, message_id } = thread;
    if (thread_reply) {
      window.location.href = `/conversations/${searchResult.conversation_id}?q=${searchResult.id}&i=${index}&_q=${query}&_t=${message_id}&_tr=true`;
    } else {
      window.location.href = `/conversations/${searchResult.conversation_id}?q=${searchResult.id}&i=${index}&_q=${query}`;
    }
  };

  const FINAL_RESOURCES_URL = `${process.env.REACT_APP_IMAGES_DATA_RESOURCES_URL}/${searchResult.conversation_detail.image}`;
  const conversationImage = searchResult.conversation_detail.image.includes("empty-filler-image.jpg")
    ? emptyImage
    : FINAL_RESOURCES_URL;

  return (
    <li className={`inbox__item`} onClick={handleResultClick}>
      <input type='hidden' className='js-input-sellerId' />
      <img src={conversationImage} alt='Seller image' className='inbox__item-img' />
      <div className='inbox__item-details search__item-details'>
        <span className='inbox__name'>
          <b>{highlightText(searchResult.conversation_detail.name, searchQuery)}</b>
        </span>
        <span className='inbox__message'>
          {/* Display last message here */}
          {highlightText(searchResult.user.display_name, searchQuery)}
        </span>
        <span className='inbox__message'>
          {/* Display last message here */}
          <small>{highlightText(trimWord(searchResult.content, 15), searchQuery)}</small>
        </span>
      </div>
      <div className='inbox__item-details inbox__item-details--2'>
        <span className='inbox__message-time'>{convertTo12HourPlusDateFormat(searchResult.createdAt)}</span>
      </div>
    </li>
  );
}

export default SearchResults;
