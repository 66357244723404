import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ChatIndex from "./layouts/index";
import SwalComponent from "./components/swal";
import "./styles/style.css";
import verifyToken from "./hooks/verifyToken";
import ApiService from "./api/apiService";
import { saveTokenToCookies, saveUserDetailsToLocalStorage } from "./hooks/accountHandler";
import { useParams, useNavigate } from "react-router-dom";
import { common } from "./util/common";

function App() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [linkConversation, setLinkConversation] = useState(null);

  const [inputValues, setInputValues] = useState({
    username: "",
    password: "",
  });

  const [userDetails, setUserDetails] = useState({
    id: "",
    display_name: "",
  });

  const verifyConversationId = async (conversation_id) => {
    try {
      const response = await ApiService.conversation.getConversationDetail(conversation_id);
      const { success } = response;
      if (!success) {
        navigate("/not-found");
      }
    } catch (err) {
      navigate("/error");
    } finally {
      setLoading(false);
    }
  };

  // VERIFY CONVERSATION ID
  useEffect(() => {
    if (id && id !== "" && id !== undefined) {
      verifyConversationId(id);
      setLinkConversation(id);
    }
  }, [id]);

  const showLoginSwal = () => {
    withReactContent(Swal)
      .fire({
        title: <b>Login</b>,
        html: `
        <input type="text" id="username" class="swal2-input" placeholder="Username" value="${inputValues.username}">
        <input type="password" id="password" class="swal2-input" placeholder="Password" value="${inputValues.password}">
      `,
        allowOutsideClick: false,
        allowEscapeKey: false,
        showCancelButton: false,
        confirmButtonText: "Login",
        confirmButtonColor: "#d33",
        preConfirm: () => {
          const username = Swal.getPopup().querySelector("#username")?.value || "";
          const password = Swal.getPopup().querySelector("#password")?.value || "";
          if (!username || !password) {
            Swal.showValidationMessage("You need to enter both username and password!");
            return false;
          }
          return { username, password };
        },
      })
      .then((result) => {
        if (result.isConfirmed) {
          const { username, password } = result.value;
          setInputValues({ username, password });
          callLoginApi(username, password);
        }
      });
  };

  const callLoginApi = async (username, password) => {
    Swal.fire({
      title: "Logging in...",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const loginApi = await ApiService.account.login(username, password);
      Swal.close();
      const { success, token, userDetails } = loginApi;
      if (success) {
        saveTokenToCookies(token);
        setUserDetails(userDetails);
        saveUserDetailsToLocalStorage(userDetails);
        SwalComponent(common.success.LOGIN_SUCCESS).then(() => {
          window.location.href = "/";
        });
      } else {
        SwalComponent(common.errors.LOGIN_FAILED).then(() => {
          showLoginSwal();
        });
      }
    } catch (err) {
      Swal.close();

      SwalComponent(common.errors.LOGIN_FAILED).then(() => {
        showLoginSwal();
      });
      console.error(err);
    }
  };

  useEffect(() => {
    const getUserDetails = () => {
      const userDetails = localStorage.getItem("userDetails");
      const _userDetails = JSON.parse(userDetails);
      setUserDetails(_userDetails);
    };
    const checkLoggedIn = async () => {
      const isLoggedIn = await verifyToken();
      if (!isLoggedIn) {
        // showLoginSwal();
        navigate("/login");
      } else {
        getUserDetails();
      }
    };

    checkLoggedIn();
  }, []);

  return (
    <div className='page'>
      <ChatIndex userDetails={userDetails} linkConversation={linkConversation} />
    </div>
  );
}

export default App;
