import React, { useState, useEffect, useRef, useContext } from "react";
import "../styles/style.css";
import emptyImage from "../images/empty.jpg";
import { ChatContext } from "../context/contexts";

function MessageTyping(props) {
  const { typingData, userDetails } = props;
  const { userId, display_name, profile_photo } = typingData;

  if (userDetails && userDetails.id === userId) return null;

  const profileImage =
    profile_photo === "" || profile_photo === null
      ? emptyImage
      : `${process.env.REACT_APP_IMAGES_DATA_RESOURCES_URL}/${profile_photo}`;
  return (
    <div>
      <div className='message__item-container message__item-container--receiver'>
        <div className='message__item-container-2'>
          <div className='user--container'>
            <img className='message__item-img' src={profileImage} alt='Seller image' />
          </div>
          <div className='message__item-message'>
            <span className='message__item-details'>{<span className='message__item-name'>{display_name}</span>}</span>

            <div>
              <div className='message__item-text-container-receiver'>
                <span className={`message__item-text message__item-text--receiver`}>
                  <div className='typing-indicator'>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MessageTyping;
