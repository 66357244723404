import React, { useState } from "react";
import ApiService from "../../api/apiService";
import showSwal from "../swal";
import { common } from "../../util/common";

function SearchInput({
  showSearchResults,
  hideSearchResults,
  isShowingSearchResults,
  setGlobalSearchResults,
  setSearchQuery: mainSetSearchQuery,
}) {
  const [searchQuery, setSearchQuery] = useState("");
  const handleSearchInputChange = (e) => {
    const target = e.target;
    setSearchQuery(target.value);
  };

  const handleSearch = async () => {
    const searchResults = await ApiService.message.globalSearchMessage({ keyword: searchQuery });
    const { success, result } = searchResults;
    if (!success) return showSwal(common.errors.GLOBAL_SEARCH_RESULT_ERROR);
    setGlobalSearchResults(result);
    mainSetSearchQuery(searchQuery);
  };

  const handleBack = () => {
    setSearchQuery("");
    mainSetSearchQuery("");
    setGlobalSearchResults([]);
    hideSearchResults();
  };
  return (
    <div className='search-input-container-header'>
      {isShowingSearchResults && (
        <div className='search-container-back' onClick={handleBack}>
          <i className='fa fa-arrow-left' aria-hidden='true'></i>
        </div>
      )}
      <div className='search-container'>
        <div className='search-icon-container'>
          <i className='fa fa-search' aria-hidden='true'></i>
        </div>
        <input
          type='text'
          className='search-input-header'
          placeholder='Search'
          onChange={(e) => handleSearchInputChange(e)}
          onClick={showSearchResults}
          value={searchQuery}
          onKeyDown={(e) => {
            if (e.key === "Enter" && e.target.value.trim() !== "") {
              e.preventDefault();
              handleSearch();
            }
          }}
        />
      </div>
    </div>
  );
}
export default SearchInput;
