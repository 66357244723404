import React, { useState, useRef, useEffect, useContext } from "react";
import env from "react-dotenv";
import "../styles/style.css";
import { convertTo12HourFormat, trimWord } from "../util/helperFunctions";
import MessageComponent from "./message";
import ApiService from "../api/apiService";
import showSwal from "./swal";
import { common } from "../util/common";
import { WebSocketContext, ChatContext } from "../context/contexts";
import emojiReaction from "../images/emotion.png";

import ReactionsIndicator from "./reusable/reactions-indicator";
import { groupReactions } from "../util/helperFunctions";
import emptyImage from "../images/empty.jpg";

function MessageSender(props) {
  const WS = useContext(WebSocketContext);
  const Chat = useContext(ChatContext);
  const {
    messageDetail,
    userDetails,
    handleImageClick,
    toggleShowChatReply,
    setChatReplyData,
    toggleShowReactionOptions,
    onClickPin,
  } = props;
  const {
    id: chat_id,
    conversation_id,
    content,
    createdAt,
    is_deleted,
    message_attachment,
    reply_to_message_detail,
    user,
    is_pinned,
    thread_replies,
    message_reactions,
    message_reads,
  } = messageDetail;

  const [isShowChatOptions, setShowChatOptions] = useState(false);
  const [showReactionOptionList, setShowReactionOptionList] = useState(false);
  const dropdownRef = useRef(null);

  const groupedReaction = groupReactions(message_reactions);

  const messageContainerRef = useRef(null);

  const toggleShowChatOptions = (e) => {
    const target = e.target;
    const parentElement = target.closest(".message__item-container");

    if (parentElement.style.zIndex === "9999999") {
      parentElement.style.zIndex = "1";
    } else {
      parentElement.style.zIndex = "9999999";
    }

    setShowChatOptions((prevState) => !prevState);
  };

  const handleOnClickPin = (e) => {
    const target = e.target;
    const parentElement = target.closest(".message__item-container");

    if (parentElement.style.zIndex === "9999999") {
      parentElement.style.zIndex = "1";
    } else {
      parentElement.style.zIndex = "9999999";
    }

    onClickPin(conversation_id, chat_id);
  };

  const handleOnThreadreply = (e) => {
    const target = e.target;
    const parentElement = target.closest(".message__item-container");

    if (parentElement.style.zIndex === "9999999") {
      parentElement.style.zIndex = "1";
    } else {
      parentElement.style.zIndex = "9999999";
    }

    Chat.handleThreadViewMsgId(chat_id);
  };
  const handleOnClickReply = () => {
    const replyData = {
      chat_id,
      conversation_id,
      display_name: user.display_name,
      message: content,
    };

    setShowChatOptions(false);
    setChatReplyData(replyData);
  };

  const handleDelete = async (messageId) => {
    try {
      const deleteMsg = await ApiService.message.deleteMessage(conversation_id, messageId);

      const { success, conversation_action } = deleteMsg;
      if (!success) showSwal(common.errors.DELETE_MESSAGE_ERROR);
      WS.websocketInstance.sendMessage({ message: conversation_action, type: "user-actions-chat" });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowChatOptions(false);
        messageContainerRef.current.style.zIndex = 1;
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const hasAttachment = message_attachment.length > 0;

  const FINAL_RESOURCES_URL = process.env.REACT_APP_CHAT_RESOURCES_URL;

  const userDisplayName = userDetails.display_name;
  const isReplyingToOwn = reply_to_message_detail?.user?.display_name === userDisplayName;

  const messagesReadsUserNames = message_reads
    ?.filter((messageRead) => messageRead?.user?.display_name)
    .filter((messageRead, index, self) => {
      const userId = messageRead.user.id;
      return index === self.findIndex((msg) => msg.user.id === userId);
    })
    .map((messageRead) => (messageRead.user.id === userDetails.id ? "You" : messageRead.user.display_name));

  return (
    <>
      <div className='message__item-container message__item-container--sender' ref={messageContainerRef}>
        {reply_to_message_detail && (
          <div className='message__item-container-reply'>
            <small className='message__item-container-reply--detail'>
              You replied to {isReplyingToOwn ? "yourself" : reply_to_message_detail.user.display_name}
            </small>
            <span className='message__item-container-reply-detail'>
              {reply_to_message_detail.is_deleted ? "DELETED CHAT" : trimWord(reply_to_message_detail.content, 25)}
            </span>
          </div>
        )}

        <div className='message__item-message'>
          <span className='message__item-details'>
            {/* <span className='message__item-time'>{convertTo12HourFormat(createdAt)}</span> */}
          </span>
          <div>
            <div className='message__item-text-container-sender' id={chat_id}>
              {is_pinned && (
                <div className='message-pin-container'>
                  <div>📌</div>
                </div>
              )}
              <span
                className={`message__item-text message__item-text--sender message__item-mouse-enter ${
                  is_deleted ? "message__item-deleted" : ""
                }`}
                onMouseEnter={(e) => {
                  if (e.target.nextElementSibling) {
                    e.target.nextElementSibling.style.display = "flex";
                  }
                }}
                onMouseLeave={(e) => {
                  if (e.target.nextElementSibling) {
                    e.target.nextElementSibling.style.display = "none";
                  }
                }}
              >
                <MessageComponent message={content} is_deleted={is_deleted} />

                {!is_deleted &&
                  hasAttachment &&
                  message_attachment.map((_messageAttachment, index) => {
                    const attachmentType = hasAttachment ? _messageAttachment.file_type : null;
                    const isImageAttachment = attachmentType && attachmentType.includes("image");
                    const isFileAttachment = attachmentType && attachmentType.includes("pdf");
                    const isVideoAttachment = attachmentType && attachmentType.includes("video");

                    const attachmentLink = `${FINAL_RESOURCES_URL}${_messageAttachment.attachment}`;

                    if (isImageAttachment) {
                      return (
                        <img
                          key={index}
                          src={attachmentLink}
                          alt='Attachment'
                          className='message__item-attachment'
                          onClick={() => handleImageClick([{ src: attachmentLink, type: "image" }])}
                          style={{ cursor: "pointer" }}
                        />
                      );
                    } else if (isVideoAttachment) {
                      return (
                        <video
                          key={index}
                          controls
                          src={attachmentLink}
                          className='message__item-attachment'
                          style={{ cursor: "pointer" }}
                          onClick={() =>
                            handleImageClick([{ type: "video", sources: [{ src: attachmentLink, type: "video/mp4" }] }])
                          }
                        >
                          Your browser does not support the video tag.
                        </video>
                      );
                    } else if (isFileAttachment) {
                      return (
                        <a
                          key={index}
                          href={attachmentLink}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='message__item-attachment-file'
                        >
                          Click to view the file
                        </a>
                      );
                    } else {
                      return (
                        <a
                          key={index}
                          href={attachmentLink}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='message__item-attachment-file'
                        >
                          Click to view the file
                        </a>
                      );
                    }
                  })}
              </span>
              <span className={`message__item-text message__item-text--sender message__item-time`}>
                <p>{convertTo12HourFormat(createdAt)}</p>
              </span>
              {!is_deleted && (
                <div className='message__item-dropdown' ref={dropdownRef}>
                  <button type='button' onClick={(e) => toggleShowChatOptions(e)} className='reaction-option-emoji-btn'>
                    <i className='fa fa-ellipsis-h message__item-ellipsis'></i>
                  </button>
                  <div
                    className={`message__item-dropdown-item message-options-sender ${isShowChatOptions ? "show" : ""}`}
                  >
                    <button type='button' onClick={(e) => handleOnClickPin(e)}>
                      Pin
                    </button>
                    <button type='button' onClick={handleOnClickReply}>
                      Reply
                    </button>
                    <button type='button' onClick={(e) => handleOnThreadreply(e)}>
                      Reply in thread
                    </button>
                    <button type='button' onClick={(e) => handleDelete(chat_id)}>
                      Delete
                    </button>
                  </div>
                </div>
              )}
              <div className='message__item-dropdown'>
                {!is_deleted && (
                  <button
                    type='button'
                    onClick={(e) => toggleShowReactionOptions(chat_id, groupedReaction)}
                    className='reaction-option-emoji-btn'
                  >
                    <img src={emojiReaction} className='reaction-option-emoji' />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className='view-chat-reactions'>
          {Object.keys(groupedReaction).map((reaction, index) => (
            <ReactionsIndicator reaction={`${reaction}`} reaction_details={groupedReaction[reaction]} key={index} />
          ))}
        </div>
        {thread_replies.length > 0 && (
          <div className='view-thread-replies'>
            <small className='thread-indicator' onClick={(e) => Chat.handleThreadViewMsgId(chat_id)}>
              {thread_replies.length} Thread Replies
            </small>
          </div>
        )}
        {messagesReadsUserNames && messagesReadsUserNames.length > 0 && (
          <div className='seen-members-container-sender seen-members-sender'>
            <small className='seen-members-text-sender'>Seen by {messagesReadsUserNames.join(", ")}</small>
          </div>
        )}
      </div>
    </>
  );
}

export default MessageSender;
