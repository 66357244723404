import React, { useState } from "react";
import "yet-another-react-lightbox/styles.css";
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Video from "yet-another-react-lightbox/plugins/video";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

function ImageViewer({ openLightbox, setOpenLightbox, lightboxSrcs }) {
  return (
    <>
      <Lightbox
        open={openLightbox}
        close={() => setOpenLightbox(false)}
        slides={lightboxSrcs}
        plugins={[Fullscreen, Video, Zoom]}
      />
    </>
  );
}

export default ImageViewer;
